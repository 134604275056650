import React, { useState, useEffect, useCallback } from 'react';
import { Box } from "@mui/material";
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

// Load existing feature settings for project
export async function getFeatureProjectSettings(projectId) {
    var url = `${process.env.REACT_APP_API_URL}/features/feature_project/${projectId}`;
    const response = axios(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.access_token}`,
        },
    }).then(result => {
        return result;
    }).catch(error => { return error.message; })
    return response;
}

// Update feature
export async function updateFeatureSettings (id, value, projectId, featureSettings) {
    var url = `${process.env.REACT_APP_API_URL}/features/feature_project`;
    var method = "POST"
    if (featureSettings !== null) {
        method = "PUT"
        url = `${process.env.REACT_APP_API_URL}/features/feature_project/${id}`;
    }
    const response = axios(url, {
        method: method,
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.access_token}`,
        },
        data: {
            "feature_id": "1",
            "project_id": projectId,
            "is_active": value
        },
    }).then(result => {
        return result;
    }).catch(error => { return error.message; })
    return response;
}

export async function postFeatureSettings (id, projectId) {
    var url = `${process.env.REACT_APP_API_URL}/features/feature_project`;
    var method = "POST"
    const response = axios(url, {
        method: method,
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.access_token}`,
        },
        data: {
            "feature_id": id,
            "project_id": projectId,
            "is_active": true
        },
    }).then(result => {
        return result;
    }).catch(error => { return error.message; })
    return response;
}

export default function FeaturesManagementProject({ projectId }) {
    const [featureSettings, setFeatureSettings] = useState(null)
    const [featuresEnabledAdmin, setFeaturesEnabledAdmin] = useState()
    const [featureToggles, setFeatureToggles] = useState()
    //const [awsSecurityHubChecked, setAwsSecurityHubChecked] = useState()
    const [featureId, setFeatureId] = useState(0)
    const [feedbackMsg, setFeedbackMsg] = useState()

    // Load existing feature settings
    const getFeatureSettings = useCallback(async () => {
        var url = `${process.env.REACT_APP_API_URL}/features`;
        const response = axios(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.access_token}`,
            },
        }).then(result => {
            return result;
        }).catch(error => { return error.message; })
        return response;
    }, [])

    const loadSettings = useCallback(async () => {
        const response = await getFeatureSettings()
        if (response?.data) {
            //console.log(response.data)
            //add to array
            let arr = []
            response?.data?.map((item) => {
                let obj = { id: item.id, is_active: item.is_active, name: item.name }
                arr.push(obj)
                return obj
            })
            setFeaturesEnabledAdmin(arr)
        } else {
            setFeedbackMsg("Your system administrator has not enabled any features")
        }
        const responsePro = await getFeatureProjectSettings(projectId)
        if (responsePro?.data) {
            setFeatureSettings(responsePro?.data)
            setFeatureId(responsePro?.data?.id)
        } /*else {
            setFeedbackMsg("Your system administrator has not enabled any features")
        }*/
    }, [getFeatureSettings, projectId])

    const enableDisableFeature = useCallback(async (id, value) => {
        //console.log(`id = ${id} value = ${value}`)
        if (value) {
            await updateFeatureSettings(id, false, projectId, featureSettings)
            loadSettings()
        }
        if (!value) {
            await updateFeatureSettings(id, true, projectId, featureSettings)
            loadSettings()
        }
    }, [loadSettings, featureSettings, projectId])

    const postProjectFeature = useCallback(async (id) => {
        await postFeatureSettings(id, projectId)
        loadSettings()
    }, [loadSettings, projectId])


    useEffect(() => {
        if (!featureSettings) {
            loadSettings()
        }
    }, [loadSettings, featureSettings])

    useEffect(() => {
    }, [featureId])

    useEffect(() => {
        if (featuresEnabledAdmin?.length > 0) {
            let toggles = []
            featuresEnabledAdmin.map((item) => {
                if (item.is_active) {
                    //check if it's active for project
                    if (featureSettings !== null) {
                        toggles.push(<TableRow>
                            <TableCell>{item.name}</TableCell>
                            <TableCell><Switch name={item.name} onChange={() => { enableDisableFeature(featureSettings.id, featureSettings.is_active); }} checked={featureSettings.is_active} /></TableCell>
                        </TableRow>)

                    }
                    if (featureSettings === null) {
                        console.log('feature settings are not there')
                        toggles.push(<TableRow>
                            <TableCell>{item.name}</TableCell>
                            <TableCell><Switch name={item.name} onChange={() => { postProjectFeature(item.id); }} checked={false} /></TableCell>
                        </TableRow>)

                    }


                }
                return item
            })
            setFeatureToggles(toggles)
        }
    }, [featuresEnabledAdmin, featureSettings, enableDisableFeature, postProjectFeature])

    if (feedbackMsg) {
        return (
            <Stack sx={{ width: '100%' }} spacing={2} className="alerts">
                <Alert severity="warning">{feedbackMsg}</Alert>
            </Stack>
        )
    }


    return (
        <>
            <Box sx={{ p: 3 }}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableCell>Feature</TableCell>
                        <TableCell>Enabled</TableCell>
                    </TableHead>
                    <TableBody>
                        {featureToggles}
                    </TableBody>
                </Table>
            </Box>
        </>
    );
}