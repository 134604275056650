import React, { useState, useEffect, useCallback, useMemo, lazy, Suspense } from 'react';
import { MenuItem, Select } from '@mui/material';
import { useForm, Controller } from "react-hook-form";
import axios from 'axios';
import SpinnerComponent from '../components/LoadingSpinner';
const ComplianceCharts = lazy(() => import('../components/graphs/ComplianceCharts'));

/* 
********START all endpoints called
*/

export async function getProjectData() {
  var url;
  url = `${process.env.REACT_APP_API_URL}/projects/get_user_projects/${localStorage.getItem("userid")}`
  if (localStorage.getItem("system_role") === "4") {
    url = `${process.env.REACT_APP_API_URL}/projects/tenant/`
  }
  const response = await axios(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.access_token}`,
    },
  }).then(result => {
    return result;
  }).catch(error => { return error.message; })
  return response;
}

export async function getChartData(id) {
  var url = `${process.env.REACT_APP_API_URL}/projects/${id}`;
  const response = await axios(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.access_token}`,
    },
  }).then(result => {
    return result;
  }).catch(error => { return error.message; })
  return response;
}

/* 
********END all endpoints called
*/
//Formats all audit tests to display on chart
export async function setAuditsByMonthData(audit_tests) {
  let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July',
    'August', 'September', 'October', 'November', 'December'];
  var dates = [];
  for (let x = 0; x < audit_tests.length; x++) {
    let tmpdate = new Date(audit_tests[x].created_date)
    dates.push(tmpdate.toLocaleString('default', { month: 'long' }))
  }
  var matches = 0;
  var matchdata = [];
  for (let y = 0; y < months.length; y++) {
    for (let x = 0; x < dates.length; x++) {
      if (months[y] === dates[x]) {
        matches++;
      }
    }
    matchdata.push({ x: months[y], y: matches });
    matches = 0;
  }
  var tmpchartdata = [];
  matchdata.forEach(md => {
    tmpchartdata.push(
      {
        x: md.x,
        y: md.y,
      },
    )
  });
  var tmpchartcombo = {
    series:
      [
        {
          name: 'Actual',
          data: tmpchartdata,
        }
      ]
  }
  return tmpchartcombo;
}
//Formats all audit tests to display on chart
export async function setAuditsByStatusData(audit_tests) {
  let status = ['Not Started', 'On Going', 'Complete', 'On Hold'];
  var matches = 0;
  var matchdata = [];
  for (let y = 0; y < status.length; y++) {
    for (let x = 0; x < audit_tests.length; x++) {
      if (status[y] === audit_tests[x].status) {
        matches++;
      }
    }
    matchdata.push({ x: status[y], y: matches });
    matches = 0;
  }
  var tmpchartdata = [];
  matchdata.forEach(md => {
    tmpchartdata.push(
      {
        x: md.x,
        y: md.y,
      },
    )
  });
  var tmpchartcombo = {
    series:
      [
        {
          name: 'Actual',
          data: tmpchartdata,
        }
      ]
  }
  return tmpchartcombo;
}

export default function Compliance() {
  const [projects, setProjects] = useState([{ value: "0", label: "Please select..." }]);
  const [projectId, setProjectId] = useState();
  const [project, setProject] = useState([]);
  const [framework, setFramework] = useState([]);
  const [auditsByMonth, setAuditsByMonth] = useState([]);
  const [auditsByStatus, setAuditsByStatus] = useState([]);
  const selectOption = useMemo(() => { return { value: "0", label: "Please select..." } }, [])

  const { control, handleSubmit } = useForm({
    defaultValues: {
      project: {},
    }
  });

  const getOptions = useCallback(async () => {
    const jsonData = await getProjectData();
    if (jsonData === 'Request failed with status code 401') {
      localStorage.clear();
      //window.location.reload(false);
    }
    if (jsonData?.data) {
      const projectOptions = jsonData?.data?.map((project) => ({
        value: project.id,
        label: project.name,
      }));
      setProjects([selectOption, ...projectOptions])
    }
  }, [selectOption])

  async function getCharts(id) {
    const jsonData = await getChartData(id);
    setProject(jsonData.data);
    setFramework(jsonData.data?.project_controls[0]?.control?.framework)
    setAuditsByMonth(await setAuditsByMonthData(jsonData.data.audit_tests));
    setAuditsByStatus(await setAuditsByStatusData(jsonData.data.audit_tests));
  }

  const handleChangeProjectSelect = (event) => {
    getCharts(event.target.value)
    setProjectId(event.target.value)
  };

  useEffect(() => {
    getOptions();
  }, [getOptions]);

  useEffect(() => {
  }, [auditsByMonth]);
  useEffect(() => {
  }, [auditsByStatus]);

  return (
    <>
      <h1>Compliance</h1>
      <form onSubmit={handleSubmit()}>
        <div className="form-field control">
          <label>Select a Project</label>
          <Controller
            name="project"
            control={control}
            render={({ field }) =>
              <Select
                {...field}
                onChange={handleChangeProjectSelect}
                value={projectId ? projectId : 0}
                inputProps={{ "data-testid": "project-select" }}
              >
                {
                  projects?.map((project) => {
                    return <MenuItem
                      value={project.value}
                      key={project.value}>
                      {project.label}
                    </MenuItem>
                  })
                }
              </Select>
            }
          />
        </div>
      </form>
      <Suspense fallback={<SpinnerComponent />}><ComplianceCharts auditsByMonth={auditsByMonth} auditsByStatus={auditsByStatus} project={project} framework={framework} /></Suspense>
    </>
  );
}