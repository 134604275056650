import React, { useEffect, useState, useCallback, useMemo } from "react";
import TextField from "@mui/material/TextField";
import Button from '@mui/material/Button';
import { MenuItem, Select, FormControl, FormHelperText } from '@mui/material';
import { useForm, Controller } from "react-hook-form";
import axios from 'axios';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import DatePicker from "react-multi-date-picker";

/* This class is for creating and editing a cap poam on a project */


export async function getProjectControls(projectId) {
    var url = `${process.env.REACT_APP_API_URL}/projects/${projectId}`;

    const response = axios(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.access_token}`,
        },
    }).then(result => {
        return result;
    }).catch(error => { return error.message; })
    return response;
}

export async function getUsers() {
    const url = `${process.env.REACT_APP_API_URL}/users/get_users_tenant`
    const response = axios(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.access_token}`,
        },
    }).then(result => {
        return result;
    }).catch(error => { return error.message; })
    return response;
}

export async function getAuditTests(projectId) {
    const url = `${process.env.REACT_APP_API_URL}/audit_tests/?project_id=${projectId}&sort_by=-created_date&limit=100&offset=0`
    const response = axios(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.access_token}`,
        },
    }).then(result => {
        return result;
    }).catch(error => { return error.message; })
    return response;
}

// postFormData with safer variable handling
export async function postFormData(
    data,
    capPoamStakeholders,
    capPoamProjectControls,
    projectId,
    ownerId,
    criticalityId,
    statusId,
    editDataObj,
    auditTestId
) {
    const stakeholderIds = capPoamStakeholders
        .map(stakeholder => parseInt(stakeholder.id))
        .filter(Boolean);

    const projectControlIds = capPoamProjectControls
        .map(pc => parseInt(pc.id))
        .filter(Boolean);

    const currentDate = new Date();
    const dueDate = data.dueDate ? convertToSendDate(data.dueDate) : convertToSendDate(currentDate);

    let url = `${process.env.REACT_APP_API_URL}/cap_poams`;
    let method = "POST";
    const body = {
        project_id: projectId,
        name: data.name,
        description: data.description,
        comments: data.comments,
        owner_id: ownerId,
        stakeholder_ids: stakeholderIds,
        project_control_ids: projectControlIds,
        criticality_rating: criticalityId,
        user_defined_id: data.userDefinedId,
        audit_test_id: auditTestId,
        due_date: dueDate,
        status: statusId,
    };

    if (editDataObj) {
        method = "PUT";
        url = `${process.env.REACT_APP_API_URL}/cap_poams/${editDataObj.id}`;
    }

    try {
        const response = await axios(url, {
            method,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.access_token}`,
            },
            data: body,
        });
        return response;
    } catch (error) {
        console.error("Error posting form data:", error);
        return error.message;
    }
}

export function convertToSendDate(date) {
    return `${new Date(date).toISOString().split("T")[0]}`;
};

export default function CreateEditProjectCapPoam({ editDataObj, projectId, isOpen, controlData, auTestId }) {
    //axios error message
    const [errMsg, setErrMsg] = useState("");
    useEffect(() => {
    }, [errMsg])

    //used with users
    const [users, setUsers] = useState([]);

    //used with controls
    const [controls, setControls] = useState([]);

    //used with audit tests
    const [auditTests, setAuditTests] = useState([]);

    //used with select fields in form
    const [ownerId, setOwnerId] = useState(editDataObj?.owner_id);
    const [auditTestId, setAuditTestId] = useState(editDataObj?.audit_test_id);
    const [testStakeholderId, setTestStakeholderId] = useState(editDataObj?.stakeholder?.id);
    const [projectControlId, setProjectControlId] = useState(editDataObj?.project_control?.id);
    const [capPoamStakeholders, setCapPoamStakeholders] = useState([]);
    const [capPoamProjectControls, setCapPoamProjectControls] = useState([]);
    const [criticalityId, setCriticalityId] = useState(editDataObj?.criticality_rating);
    const [statusId, setStatusId] = useState(editDataObj?.status);
    const [error, setError] = useState(false);
    //const [selectedProjectId, setProjectId] = useState(editDataObj?.project_id);

    useEffect(() => {
        if (auTestId) {
            setAuditTestId(auTestId)
        }
    }, [auTestId])

    //default option when no value selected in drop down
    const selectOption = useMemo(() => { return { value: "0", label: "Please select..." } }, [])

    const criticality = [
        selectOption,
        { value: "Low", label: "Low" },
        { value: "Medium", label: "Medium" },
        { value: "High", label: "High" },
    ]
    const status = [
        selectOption,
        { value: "Not Started", label: "Not Started" },
        { value: "In Progress", label: "In Progress" },
        { value: "Completed", label: "Completed" },
    ]

    const { control, handleSubmit } = useForm({
        defaultValues: {
            name: editDataObj?.name,
            description: editDataObj?.description,
            comments: editDataObj?.comments,
            objective: editDataObj?.objective,
            userDefinedId: editDataObj?.user_defined_id,
            dueDate: editDataObj?.due_date,
            criticalitySelect: {},
            statusSelect: {},
            capPoamStakeholders: {},
            capPoamStakeholdersSelect: {},
            capPoamProjectControls: {},
            capPoamProjectControlsSelect: {},
            ownerSelect: {},
            auditTestSelect: {},
        }
    });

    // handle change events on dropdowns
    const handleChangeOwnerSelect = (event) => {
        setOwnerId(event.target.value);
        setError(false);
    }
    const handleChangeCriticalitySelect = (event) => {
        setCriticalityId(event.target.value);
    }
    const handleChangeStatusSelect = (event) => {
        setStatusId(event.target.value);
    }
    const handleChangeAuditTestSelect = (event) => {
        setAuditTestId(event.target.value);
    }

    const handleChangeCapPoamStakeholderSelect = (event) => {
        setTestStakeholderId(event.target.value);
        var useremail = users.filter(user => user.id === event.target.value)
        var email = useremail[0].email
        setCapPoamStakeholders(capPoamStakeholders => [...capPoamStakeholders, { email: email, id: event.target.value }]);
    }

    const removeStakeholder = (stake) => {
        var tmpfilter = capPoamStakeholders.filter(stakeholder => stake.id !== stakeholder.id)
        setCapPoamStakeholders(tmpfilter);
    }

    const handleChangeCapPoamProjectControlSelect = (event) => {
        setProjectControlId(event.target.value);
        var pcvalue = controls.filter(pc => pc.value === event.target.value)
        var label = pcvalue[0].label
        setCapPoamProjectControls(capPoamProjectControls => [...capPoamProjectControls, { label: label, id: event.target.value }]);
    }

    const removeProjectControl = (control) => {
        var tmpfilter = capPoamProjectControls.filter(pc => control.id !== pc.id)
        setCapPoamProjectControls(tmpfilter);
    }

    /* form submit */
    const onSubmit = async (data) => {
        if (!ownerId) {
            setError(true);
        }
        const response = await postFormData(data, capPoamStakeholders, capPoamProjectControls, projectId, ownerId, criticalityId, statusId, editDataObj, auditTestId);
        if (response.status === 200) {
            controlData(response.data)
            isOpen(false);
        } else {
            setErrMsg(`${response}`)
        }
    }

    const getDropDownOptions = useCallback(async () => {
        const jsonData = await getProjectControls(projectId);
        if (jsonData?.data?.project_controls) {
            const projectControlOptions = jsonData?.data?.project_controls?.map((control) => ({
                value: control.id,
                label: control.control.name,
            }));
            var allControlOptions = [selectOption, ...projectControlOptions];
            setControls(allControlOptions);
        }
        const jsonDataUsers = await getUsers();
        if (jsonDataUsers?.data) {
            const tmpoptions = jsonDataUsers?.data?.map((user) => ({
                id: user.id,
                email: user.email,
            }));
            setUsers(tmpoptions);
        }
        const jsonDataAuditTests = await getAuditTests(projectId);
        if (jsonDataAuditTests?.data?.items) {
            const auditTestOptions = jsonDataAuditTests?.data?.items?.map((at) => ({
                value: at.id,
                label: at.name,
            }));
            var allATOptions = [selectOption, ...auditTestOptions];
            setAuditTests(allATOptions);
        }

    }, [projectId, selectOption])



    useEffect(() => {
        getDropDownOptions();
    }, [getDropDownOptions]);

    useEffect(() => {
        if (editDataObj?.stakeholders) {
            setCapPoamStakeholders(editDataObj?.stakeholders)
        }
    }, [editDataObj])

    useEffect(() => {
        if (editDataObj?.project_controls) {
            let proj_controls_format = []
            for (let item of editDataObj?.project_controls) {
                let new_cont = {
                    id: item.project_control.id,
                    label: item.project_control.control.name
                }
                proj_controls_format.push(new_cont)
            }
            setCapPoamProjectControls(proj_controls_format)
        }
    }, [editDataObj])

    useEffect(() => {

    }, [testStakeholderId, capPoamStakeholders, capPoamProjectControls]);

    return (
        <div className="create edit project cap poam">
            {errMsg ? (
                <Stack sx={{ width: '100%' }} spacing={2} className="alerts">
                    <Alert severity="error">{errMsg}</Alert>
                </Stack>
            ) : (
                <></>
            )}
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-field name">
                    <Controller
                        name="name"
                        control={control}
                        render={({ field }) => <TextField required label="Name" {...field} fullWidth />}
                    />
                </div>
                <div className="form-field description">
                    <Controller
                        name="description"
                        control={control}
                        render={({ field }) =>
                            <TextField
                                {...field}
                                multiline
                                rows={4}
                                label="Description"
                                fullWidth
                            />
                        }
                    />
                </div>
                <div className="form-field comments">
                    <Controller
                        name="comments"
                        control={control}
                        render={({ field }) =>
                            <TextField
                                {...field}
                                multiline
                                rows={4}
                                label="Comments"
                                fullWidth
                            />
                        }
                    />
                </div>
                <div className="form-field userdefined-id">
                    <Controller
                        name="userDefinedId"
                        control={control}
                        render={({ field }) => <TextField label="User Defined ID" {...field} fullWidth />}
                    />
                </div>

                <div className="form-field control">
                    <label>Criticality Rating</label>
                    <Controller
                        name="criticalitySelect"
                        control={control}
                        defaultValue={0}
                        render={({ field }) =>
                            <Select
                                {...field}
                                value={criticalityId ? criticalityId : 0}
                                onChange={handleChangeCriticalitySelect}
                                defaultValue={0}
                                inputProps={{ "data-testid": "test-frequency" }}
                            >
                                {
                                    criticality?.map((tf) => {
                                        return <MenuItem
                                            value={tf.value}
                                            key={tf.value}>
                                            {tf.label}
                                        </MenuItem>
                                    })
                                }
                            </Select>
                        }
                    />
                </div>
                <div className="form-field control">
                    <label>Status</label>
                    <Controller
                        name="statusSelect"
                        control={control}
                        defaultValue={0}
                        render={({ field }) =>
                            <Select
                                {...field}
                                onChange={handleChangeStatusSelect}
                                defaultValue={0}
                                value={statusId ? statusId : 0}
                                inputProps={{ "data-testid": "cap_poam-status" }}
                            >
                                {
                                    status?.map((ts) => {
                                        return <MenuItem
                                            value={ts.value}
                                            key={ts.value}>
                                            {ts.label}
                                        </MenuItem>
                                    })
                                }
                            </Select>
                        }
                    />
                </div>
                <div className="form-field control owner">
                    <FormControl fullWidth error={error}>
                        <TextField
                            select
                            labelId="owner-select-label"
                            id="owner-select"
                            value={ownerId}
                            onChange={handleChangeOwnerSelect}
                            label="Please select an owner"
                            required
                        >
                            {
                                users?.map((user) => {
                                    return <MenuItem
                                        value={user.id}
                                        key={user.id}>
                                        {user.email}
                                    </MenuItem>
                                })
                            }
                        </TextField>
                        {error && <FormHelperText>This field is required</FormHelperText>}
                    </FormControl>
                </div>
                <div className="form-field control audit test">
                    <label>Audit Test</label>
                    <Controller
                        name="auditTestSelect"
                        control={control}
                        defaultValue={0}
                        render={({ field }) =>
                            <Select
                                {...field}
                                onChange={handleChangeAuditTestSelect}
                                defaultValue={0}
                                value={auditTestId ? auditTestId : 0}
                                inputProps={{ "data-testid": "auditTest" }}
                            >
                                {
                                    auditTests?.map((at) => {
                                        return <MenuItem
                                            value={at.value}
                                            key={at.value}>
                                            {at.label}
                                        </MenuItem>
                                    })
                                }
                            </Select>
                        }
                    />
                </div>
                <div className="form-field control stakeholders">
                    <FormControl fullWidth error={error}>
                        <TextField
                            select
                            labelId="stakeholder-select-label"
                            id="stakeholder-select"
                            value={testStakeholderId}
                            onChange={handleChangeCapPoamStakeholderSelect}
                            label="Please select stakeholders"
                        >
                            {
                                users?.map((user) => {
                                    return <MenuItem
                                        value={user.id}
                                        key={user.id}>
                                        {user.email}
                                    </MenuItem>
                                })
                            }
                        </TextField>
                    </FormControl>
                </div>
                <ul>
                    {(capPoamStakeholders && (
                        capPoamStakeholders.map((stakeholder) => {
                            return <li>
                                {stakeholder.email}
                                <IconButton aria-label="delete">
                                    <DeleteIcon onClick={() => { removeStakeholder(stakeholder); }} />
                                </IconButton>
                            </li>
                        })
                    )
                    )}
                </ul>

                <div className="form-field control project-control">
                    <label>Project Controls</label>
                    <Controller
                        name="capPoamProjectControlsSelect"
                        control={control}
                        defaultValue={0}
                        render={({ field }) =>
                            <Select
                                {...field}
                                value={projectControlId ? projectControlId : 0}
                                defaultValue={0}
                                onChange={handleChangeCapPoamProjectControlSelect}
                                inputProps={{ "data-testid": "projectControl" }}
                            >
                                {
                                    controls?.map((control) => {
                                        return <MenuItem
                                            value={control.value}
                                            key={control.value}>
                                            {control?.label}
                                        </MenuItem>
                                    })
                                }
                            </Select>
                        }
                    />
                </div>

                <ul>
                    {(capPoamProjectControls && (
                        capPoamProjectControls.map((control) => {
                            return <li>
                                {control.label}
                                <IconButton aria-label="delete">
                                    <DeleteIcon onClick={() => { removeProjectControl(control); }} />
                                </IconButton>
                            </li>
                        })
                    )
                    )}
                </ul>
                <div className="form-field due date">
                    <label>Due Date *</label>
                    <Controller
                        control={control}
                        name="dueDate"
                        rules={{ required: true }} //optional
                        render={({
                            field: { onChange, name, value },
                            //fieldState: { invalid, isDirty }, //optional
                            formState: { errors }, //optional, but necessary if you want to show an error message
                        }) => (
                            <>
                                <DatePicker
                                    value={value || ""}
                                    onChange={(date) => {
                                        onChange(date?.isValid ? date : "");
                                    }}
                                />
                                {errors && errors[name] && errors[name].type === "required" && (
                                    //if you want to show an error message
                                    <span>The due date is required!</span>
                                )}
                            </>
                        )}
                    />
                </div>
                <div>
                    {
                        (editDataObj && <Button type="submit" variant="contained" >Submit</Button>) ||
                        (!editDataObj && <Button type="submit" variant="contained" >Create</Button>)
                    }
                </div>
            </form>
        </div>
    )
}