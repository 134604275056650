import React, { useEffect, useState, useMemo, useCallback } from "react";
import TextField from "@mui/material/TextField";
import Button from '@mui/material/Button';
import { MenuItem, Select } from '@mui/material';
import { useForm, Controller } from "react-hook-form";
import axios from 'axios';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

export async function getKeywords() {
    const url = `${process.env.REACT_APP_API_URL}/keyword/`
    const response = axios(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.access_token}`,
        },
    }).then(result => {
        return result;
    }).catch(error => { return error.message; })
    return response;
}

export default function CreateEditProjectControlAssessment({ editObj, isOpen, projectId, controlData }) {
    //console.log(editObj)
    const [errMsg, setErrMsg] = useState("");

    //default option when no value selected in drop down
    const selectOption = { value: 0, label: "Please select..." };

    //used with select fields in form
    const [assessmentStatusId, setAssessmentStatusId] = useState(editObj?.control?.status?.id);
    const [assessmentConfirmedId, setAssessmentConfirmedId] = useState(editObj?.control?.confirmed?.id);

    const [keywords, setKeywords] = useState(null);

    const [keywordSelections, setKeywordSelections] = useState([]);

    const [keywordId, setKeywordId] = useState();
    const keywordSelectOption = useMemo(() => { return { id: "0", name: "Please select..." } }, [])

    const removeKeyword = (key) => {
        var tmpfilter = keywordSelections.filter(keyword => keyword.id !== key.id)
        setKeywordSelections(tmpfilter);
        var keyword = keywordSelections.filter(word => word.id === key.id)
        var name = keyword[0].name
        setKeywords(keywords => [...keywords, { name: name, id: key.id }]);
    }

    const getOptions = useCallback(async () => {
        const jsonDataKey = await getKeywords();
        const tmpoptions = jsonDataKey.data.map((word) => ({
            id: word.id,
            name: word.name,
        }));
        var allOptions = [keywordSelectOption, ...tmpoptions];
        setKeywords(allOptions);
    }, [keywordSelectOption])

    useEffect(() => {
        if (keywords === null) {
            getOptions()
        }
    }, [keywords, getOptions])

    useEffect(() => {
        if (editObj?.keywords) {
            let options = []
            for (let i = 0; i < editObj?.keywords.length; i++) {
                options.push({ id: editObj?.keywords[i].id, name: editObj?.keywords[i].keyword.name })
            }
            setKeywordSelections(options)
        }
    }, [editObj])

    const handleChangeKeywordSelect = (event) => {
        setKeywordId(event.target.value);
        var keyword = keywords.filter(word => word.id === event.target.value)
        var name = keyword[0].name
        setKeywordSelections(keywordSelections => [...keywordSelections, { name: name, id: event.target.value }]);
        var tmpfilterSelect = keywords.filter(keyword => keyword.id !== event.target.value)
        setKeywords(tmpfilterSelect)
    }

    const assessmentStatus = [
        selectOption,
        { value: "Not Started", label: "Not Started" },
        { value: "In Progress", label: "In Progress" },
        { value: "Complete", label: "Complete" },
    ]

    const assessmentConfirmed = [
        selectOption,
        { value: "Yes", label: "Yes" },
        { value: "No", label: "No" },
    ]

    const { control, handleSubmit } = useForm({
        defaultValues: {
            name: editObj?.name,
            assessmentStatusSelect: {},
            assessmentConfirmedSelect: {},
            comments: editObj?.comments,
            keywords: editObj?.keywords,
            description: editObj?.description,
            newKeywords: "",
            keywordSelect: {},
        }
    });

    // handle change events on dropdowns
    const handleChangeAssessmentStatusSelect = (event) => {
        setAssessmentStatusId(event.target.value);
    }
    const handleChangeAssessmentConfirmedSelect = (event) => {
        setAssessmentConfirmedId(event.target.value);
    }

    /* form submit */
    const onSubmit = async (data) => {
        var datastore = data;
        const response = await postFormData(data);
        datastore.id = response.data.id;
        controlData(response.data);
        isOpen(false);
    }

    async function postFormData(data) {
        //look at keywords
        if (!/^[a-z,]*$/.test(data?.newKeywords)) {
            setErrMsg(`Sorry your keywords contain values other than lowercase characters and commas`)
        } else {
            //clean up duplicate vals
            var cleanedKeywords = []
            var enteredKeywords = data.newKeywords.split(',')
            for (let x = 0; x < enteredKeywords.length; x++) {
                let match = false
                for (let y = 0; y < keywordSelections.length; y++) {
                    if (enteredKeywords[x] === keywordSelections[y].name) {
                        match = true
                    }
                }
                if (match === false) {
                    cleanedKeywords.push(enteredKeywords[x])
                }
            }
            for (let p = 0; p < keywordSelections.length; p++) {
                cleanedKeywords.push(keywordSelections[p].name)
            }
            var cleanedWords = cleanedKeywords.join(',')
            var url = `${process.env.REACT_APP_API_URL}/assessments/?keywords=${cleanedWords}`;
            var method = "POST";
            var body = {
                "name": data.name,
                "description": data.description,
                "comments": data.comments,
                "status": assessmentStatusId,
                "is_assessment_confirmed": assessmentConfirmedId
              }
            //if a data object is being edited
            if (editObj) {
                method = "PUT";
                url = `${process.env.REACT_APP_API_URL}/assessments/${editObj.id}?keywords=${cleanedWords}`;
            }
            const response = axios(url, {
                method: method,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.access_token}`,
                },
                data: body,
            }).then(result => {
                return result;
            }).catch(error => { return error.message; })
            return response;
        }
    }

    useEffect(() => {
    }, [errMsg])

    useEffect(() => {
    }, [editObj])

    return (
        <div className="create edit assessment">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-field name">
                    <Controller
                        name="name"
                        control={control}
                        render={({ field }) => <TextField required label="Name" {...field} fullWidth />}
                    />
                </div>

                <div className="form-field description">
                    <Controller
                        name="description"
                        control={control}
                        render={({ field }) =>
                            <TextField
                                {...field}
                                multiline
                                rows={4}
                                label="Description"
                                fullWidth
                            />
                        }
                    />
                </div>

                <div className="form-field control">
                    <label>Assessment Status</label>
                    <Controller
                        name="assessmentStatusSelect"
                        control={control}
                        render={({ field }) =>
                            <Select
                                {...field}
                                onChange={handleChangeAssessmentStatusSelect}
                                value={assessmentStatusId ? assessmentStatusId : editObj?.status}
                            >
                                {
                                    assessmentStatus.map((assessment) => {
                                        return <MenuItem
                                            value={assessment.value}
                                            key={assessment.value}>
                                            {assessment.label}
                                        </MenuItem>
                                    })
                                }
                            </Select>
                        }
                    />
                </div>

                <div className="form-field control">
                    <label>CAP / PO&AM (Plan of Action & Milestone) Assessment Confirmed</label>
                    <Controller
                        name="assessmentConfirmedSelect"
                        control={control}
                        render={({ field }) =>
                            <Select
                                {...field}
                                onChange={handleChangeAssessmentConfirmedSelect}
                                value={assessmentConfirmedId ? assessmentConfirmedId : editObj?.is_assessment_confirmed}
                            >
                                {
                                    assessmentConfirmed.map((assessment) => {
                                        return <MenuItem
                                            value={assessment.value}
                                            key={assessment.value}>
                                            {assessment.label}
                                        </MenuItem>
                                    })
                                }
                            </Select>
                        }
                    />
                </div>

                <div className="form-field keywords">
                    <div className="form-field existing keywords">
                        <label>Existing Keywords</label>
                        <Controller
                            name="keywordSelect"
                            control={control}
                            defaultValue={0}
                            render={({ field }) =>
                                <Select
                                    {...field}
                                    value={keywordId ? keywordId : 0}
                                    defaultValue={0}
                                    onChange={handleChangeKeywordSelect}
                                >
                                    {
                                        keywords?.map((word) => {
                                            return <MenuItem
                                                value={word.id}
                                                key={word.id}>
                                                {word.name}
                                            </MenuItem>
                                        })
                                    }
                                </Select>
                            }
                        />
                    </div>
                    <ul>
                        {(keywordSelections && (
                            keywordSelections.map((word) => {
                                return <li>
                                    {word.name}
                                    <IconButton aria-label="delete">
                                        <DeleteIcon onClick={() => { removeKeyword(word); }} />
                                    </IconButton>
                                </li>
                            })
                        )
                        )}
                    </ul>
                    <div className="form-field newkeywords">
                        <Controller
                            name="newKeywords"
                            control={control}
                            render={({ field }) =>
                                <TextField
                                    {...field}
                                    label="Add new keywords"
                                    //fullWidth
                                    helperText="Enter your keyword in all lowercase with a comma and no spaces between each one e.g. legal,technical"
                                />

                            }
                        />
                    </div>
                </div>
                <div className="form-field comments">
                    <Controller
                        name="comments"
                        control={control}
                        render={({ field }) =>
                            <TextField
                                {...field}
                                multiline
                                rows={4}
                                label="Comments"
                                fullWidth
                            />
                        }
                    />
                </div>
                <div>
                    {
                        <Button type="submit" variant="contained" >Submit</Button>
                    }
                </div>
            </form>
        </div>
    )
}