import React, { useEffect, useState, useCallback } from "react";
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';
import "../../css/List.css";
import SpinnerComponent from '../LoadingSpinner';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import EditIcon from '@mui/icons-material/Edit';
import CreateEditDocument from "../CreateEditDocument";
import AddCircleIcon from '@mui/icons-material/AddCircle';

//This class displays lists of documents

export async function deleteData(id) {
    var url = `${process.env.REACT_APP_API_URL}/documents/${id}`;

    const response = axios(url, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.access_token}`,
        },
    }).then(result => {
        return result;
    }).catch(error => { return error.message; })
    return response;
}

export async function loadData (projectId)  {
    var url = `${process.env.REACT_APP_API_URL}/projects/${projectId}`;

    const response = axios(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.access_token}`,
        },
    }).then(result => {
        return result;
    }).catch(error => { return error.message; })
    return response;
}

const columns = [
    { field: 'id', headerName: 'id', width: 120 },
];

const rows = [
    { id: "n/a" },
];

export default function ProjectWizardDocumentList({
    projectId,
}) {

    //axios error message
    const [errMsg, setErrMsg] = useState("");

    const [rowsData, setRowsData] = useState(null);
    const [colsData, setColsData] = useState(null);

    const [editObj, setEditObj] = useState(null);

    const [loading, setLoading] = useState(false);

    const [openAddDialog, setOpenAddDialog] = useState(null);

    const [selected, setSelected] = useState([]);

    const [openDialog, setOpenDialog] = useState(null);

    const [isSystemAdmin, setIsSystemAdmin] = useState(null);

    const onRowsSelectionHandler = (ids) => {
        setSelected([...ids]);
    };

    useEffect(() => {

    }, [isSystemAdmin])

    useEffect(() => {
        if (localStorage.getItem("system_role") === "4" ||
            localStorage.getItem("system_role") === "1" ||
            localStorage.getItem("system_role") === "2"
        ) {
            setIsSystemAdmin(true)
        } else {
            setIsSystemAdmin(false)
        }

    }, [setIsSystemAdmin])

    const handleClose = () => {
        setOpenAddDialog(false);
        setOpenDialog(false);
        setEditObj(null);
    }

    const isOpen = () => {
        //setEditObj(null);
        handleClose();
    }

    const addControlData = (data) => {
        //var arrcopy = rowsData.filter(row => row.id !== data.id)
        //var tmparray = [...arrcopy, data];
        setRowsData([]);
        getData();
    }
    async function deleteSelectedRow() {

        if (selected.length !== 0) {
            for (let x = 0; x < selected.length; x++) {
                await deleteData(selected[x]);
                let filteredArray = rowsData?.filter(item => item.id !== selected[x])
                setRowsData(filteredArray);
            }
        }
    };

    const getData = useCallback(async () => {
        setLoading(true)
        const resultData = await loadData(projectId);
        var concatDocuments = []
        if (!resultData.data) {
            setErrMsg('No documents found')
        }
        if (resultData?.data) {
            var proj_controls = resultData?.data?.project_controls
            if (proj_controls?.length > 0) {
                var proj_control_docs = []
                for (var x = 0; x < proj_controls?.length; x++) {
                    var obj_id = proj_controls[x].id
                    var documents = proj_controls[x].documents
                    for (var y = 0; y < documents.length; y++) {
                        proj_control_docs.push({
                            id: documents[y].document.id,
                            name: documents[y].document.name,
                            title: documents[y].document.title,
                            description: documents[y].document.description,
                            owner_id: documents[y].document.owner_id,
                            keywords: documents[y].document.keywords,
                            version: documents[y].document.version,
                            type: 'project_controls',
                            fedrisk_obj_id: obj_id,
                        })
                    }
                }
                if (proj_control_docs?.length > 0) {
                    concatDocuments = [...concatDocuments, ...proj_control_docs]
                }
            }
            //get audit_tests docs
            var audit_tests = resultData.data.audit_tests
            if (audit_tests?.length > 0) {
                var audit_test_docs = []
                for (var j = 0; j < audit_tests.length; j++) {
                    var objec_id = audit_tests[j].id
                    var docos = audit_tests[j].documents
                    for (var b = 0; b < docos?.length; b++) {
                        audit_test_docs.push({
                            id: docos[b].document.id,
                            name: docos[b].document.name,
                            title: docos[b].document.title,
                            description: docos[b].document.description,
                            owner_id: docos[b].document.owner_id,
                            keywords: docos[b].document.keywords,
                            version: docos[b].document.version,
                            type: 'audit_tests',
                            fedrisk_obj_id: objec_id,
                        })
                    }
                }

                if (audit_test_docs?.length > 0) {
                    concatDocuments = [...concatDocuments, ...audit_test_docs]
                }
            }
            //get risks docs
            var risks = resultData?.data?.risks
            if (risks?.length > 0) {
                var risk_docs = []
                for (var f = 0; f < risks?.length; f++) {
                    var obje_id = risks[f].id
                    var docs = risks[f].documents
                    for (var l = 0; l < docs.length; l++) {
                        risk_docs.push({
                            id: docs[l].document.id,
                            name: docs[l].document.name,
                            title: docs[l].document.title,
                            description: docs[l].document.description,
                            owner_id: docs[l].document.owner_id,
                            keywords: docs[l].document.keywords,
                            version: docs[l].document.version,
                            type: 'risks',
                            fedrisk_obj_id: obje_id,
                        })
                    }
                }

                if (risk_docs?.length > 0) {
                    concatDocuments = [...concatDocuments, ...risk_docs]
                }
            }
            //get project docs
            var document = resultData?.data?.documents
            if (document?.length > 0) {
                var objid = resultData?.data?.id
                var project_docs = []
                for (var p = 0; p < document?.length; p++) {
                    project_docs.push({
                        id: document[p].document.id,
                        name: document[p].document.name,
                        title: document[p].document.title,
                        description: document[p].document.description,
                        owner_id: document[p].document.owner_id,
                        keywords: document[p].document.keywords,
                        version: document[p].document.version,
                        type: 'projects',
                        fedrisk_obj_id: objid.id,
                    })
                }
                if (project_docs?.length > 0) {
                    concatDocuments = [...concatDocuments, ...project_docs]
                }
            }
            setRowsData([...concatDocuments]);
            //console.log(concatDocuments)
            var colsValsDat = [
                {
                    field: 'title',
                    headerName: 'Title',
                    width: 200,
                    renderCell: (params) => (
                        <Link href={`/documents/${params.row.id}`}>{params.value}</Link>
                    )
                },
                {
                    field: 'description',
                    headerName: 'Description',
                    width: 200,
                    cellClass: "normalLineHeight"
                },
                {
                    field: 'type',
                    headerName: 'Type',
                    width: 200,
                    cellClass: "normalLineHeight"
                },
                {
                    field: 'action',
                    headerName: 'Action',
                    width: 350,
                    renderCell: (params) => (
                        <>
                            <Button startIcon={<EditIcon />} onClick={() => { setEditObj(params.row); setOpenAddDialog(true) }} />
                            <Button startIcon={<DeleteIcon />} onClick={() => { setEditObj(params.row); setOpenDialog("delete") }} />
                        </>
                    )

                },
            ];
            setColsData([...colsValsDat]);
            //console.log(colsValsDat)
        }
        setLoading(false)
    }, [projectId])

    useEffect(() => {
        if (!rowsData) {
            getData();
        }
    }, [rowsData, getData])

    return (
        <div style={{ marginTop: '0px' }}>
            <>
                {errMsg ? (
                    <Stack sx={{ width: '100%' }} spacing={2} className="alerts">
                        <Alert severity="info">{errMsg}</Alert>
                    </Stack>
                ) : (
                    <></>
                )}
                <div className="btns-top-list">
                    <Button
                        variant="contained"
                        sx={{ mr: 1 }}
                        className="secondary btn"
                        startIcon={<DeleteIcon />}
                        disabled={!selected.length}
                        onClick={() =>
                            selected.length ? setOpenDialog("delete") : null
                        }
                    >
                        Delete
                    </Button>
                    <Button
                        variant="outlined"
                        startIcon={<AddCircleIcon />}
                        onClick={() => { setOpenAddDialog(true); }}
                        className="addBtn"
                    >Add</Button>
                </div>

                <div style={{ height: '600px', width: '100%' }}>
                    <DataGrid
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
                        }
                        rows={rowsData ? rowsData : rows}
                        columns={colsData ? colsData : columns}
                        rowsPerPageOptions={[10]}
                        pageSize={10}
                        checkboxSelection
                        loading={loading}
                        components={{
                            LoadingOverlay: SpinnerComponent,
                        }}
                        onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
                        getRowHeight={() => 'auto'}
                    />
                </div>
                <Dialog
                    //fullScreen
                    open={openAddDialog}
                    onClose={handleClose}
                    aria-labelledby="add-project-data"
                    aria-describedby="alert-dialog-add-project-data"
                >
                    <DialogActions className="create-edit-dialog">
                        <Button onClick={handleClose}>Cancel</Button>
                    </DialogActions>
                    <DialogTitle id="alert-dialog-title">
                        {editObj ? `Edit Document` : `Add Document to Project`}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <CreateEditDocument dataType="projects" isOpen={isOpen} controlData={addControlData} editDataObj={editObj} dataId={projectId} projectId={projectId} />
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={openDialog}
                    onClose={handleClose}
                    aria-labelledby="delete-confirm"
                    aria-describedby="alert-dialog-delete-confirm"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Delete?"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to delete this?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className="create-edit-dialog">
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={() => { handleClose(); deleteSelectedRow(); }} autoFocus>
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        </div>
    )

}