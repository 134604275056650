import React, { useState, useEffect, useCallback } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import CreateEditProjectControl from '../components/CreateEditProjectControl';
import SpinnerComponent from '../components/LoadingSpinner';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CreateEditProjectControlException from '../components/CreateEditProjectControlException';
import '../css/ProjectControl.css';
import DocumentList from '../components/lists/DocumentList';
import AWSControlList from '../components/lists/AWSReportCardList';

/* This is a view for returning details on a project control */

export async function loadData () {
  //call data endpoint for data type to set rows and columns
  var url = `${process.env.REACT_APP_API_URL}/project_controls/${window.location.pathname.split("/")[4]}`;

  const response = axios(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.access_token}`,
    },
  }).then(result => {
    return result;
  }).catch(error => { return error.message; })
  return response;
}

  // Load existing feature settings
  export async function getFeatureSettings (projectId) {
    var url = `${process.env.REACT_APP_API_URL}/features/feature_project/${projectId}`;
    const response = axios(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.access_token}`,
      },
    }).then(result => {
      return result;
    }).catch(error => { return error.message; })
    return response;
  }

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function ProjectControl({ id, projectId }) {
  const [data, setData] = useState([]);
  const [controlData, setControlData] = useState([]);
  const [editOpen, setEditOpen] = useState(false);
  const [addExceptionOpen, setAddExceptionOpen] = useState(false);
  const [isSystemAdmin, setIsSystemAdmin] = useState(false);
  const [showReportCard, setShowReportCard] = useState(false);
  const [awsSecHubOn, setAwsSecHubOn] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("system_role") === "4" || localStorage.getItem("system_role") === "1" || localStorage.getItem("system_role") === "2") {
      setIsSystemAdmin(true)
    }
  }, [setIsSystemAdmin])

  useEffect(() => {
  }, [isSystemAdmin])


  //axios error message
  const [errMsg, setErrMsg] = useState("");
  useEffect(() => {
  }, [errMsg])

  const handleClose = () => {
    setEditOpen(false);
    setAddExceptionOpen(false);
  }

  const EditData = () => {
    setEditOpen(true);
  }

  useEffect(() => {
  }, [projectId])

  const isOpen = () => {
    handleClose();
  }

  const addControlData = (data) => {
    getData();
  }

  const getData = useCallback(async () => {
    const jsonData = await loadData();
    if (jsonData !== "Request failed with status code 404" && jsonData !== "Network Error") {
      setData(jsonData.data);
      setControlData(jsonData.data)
      const jsonDataFeatures = await getFeatureSettings(projectId);
      if (jsonDataFeatures.status !== 200) {
        //handle the error
        //setErrMsg(`${jsonData.status}`)
      }
      if (jsonDataFeatures.status === 200 && jsonDataFeatures.data) {
        setAwsSecHubOn(jsonDataFeatures.data.is_active)
      }
    } else {
      setErrMsg(`${jsonData}`)
    }
  }, [projectId])

  useEffect(() => {
    if (data.length === 0) {
      getData();
    }
  }, [getData, data.length]);

  useEffect(() => {
    //console.log(data)
  }, [data])

  return (
    <>
      {errMsg ? (
        <Stack sx={{ width: '100%' }} spacing={2} className="alerts">
          <Alert severity="error">{errMsg}</Alert>
        </Stack>
      ) : (
        <></>
      )}
      <div className="details-view projectControl">
        {(!data && <SpinnerComponent />)}
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/system-administration">
            System Administration
          </Link>
          <Link underline="hover" color="inherit" href={`/projects/list`}>
            Projects
          </Link>
          <Link underline="hover" color="inherit" href={`/projects/${data?.project?.id}`}>
            {data?.project?.name}
          </Link>
          <Link underline="hover" color="inherit" href={`/projects/${projectId}/controls`}>
            Controls
          </Link>
          <Typography color="text.primary">{data?.control?.name}</Typography>
        </Breadcrumbs>
        <h1>{data?.control?.name}</h1>
        <div className="action-buttons">
          {isSystemAdmin ? <Button variant="contained" className="editBtn" onClick={EditData}>Edit</Button> : <></>}
          {isSystemAdmin && !data?.exception ? <Button variant="outlined" onClick={() => { setAddExceptionOpen(true); }}>Create Exception</Button> : <></>}
        </div>
        {showReportCard === true && <>{awsSecHubOn && <Button variant="contained" onClick={() => { setShowReportCard(false) }}>Detail</Button>}
          <AWSControlList awsControlData={data?.aws_controls} />
        </>}
        {showReportCard === false && <><div className="leftCol">{awsSecHubOn && <Button variant="contained" onClick={() => { setShowReportCard(true) }}>AWS Report Card</Button>} <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableBody>
              <StyledTableRow >
                <StyledTableCell component="th" scope="row">
                  <strong>Description</strong>
                </StyledTableCell>
                <StyledTableCell align="left">
                  {data?.control?.description}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow >
                <StyledTableCell component="th" scope="row">
                  <strong>Framework Versions</strong>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <ul>
                    {data?.control?.framework_versions?.map((fv) => {
                      return (
                        <li>
                          <Link href={`/frameworks/${fv?.framework?.id}/framework_versions/${fv?.id}`}>
                            {fv?.version_prefix + " " + fv?.framework?.name + " " + fv?.version_suffix}
                          </Link>
                        </li>
                      )
                    })}
                  </ul>

                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow >
                <StyledTableCell component="th" scope="row">
                  <strong>Control Family</strong>
                </StyledTableCell>
                <StyledTableCell align="left">
                  {data?.control_family?.name}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow >
                <StyledTableCell component="th" scope="row">
                  <strong>Control Phase</strong>
                </StyledTableCell>
                <StyledTableCell align="left">
                  {data?.control_phase?.name}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow >
                <StyledTableCell component="th" scope="row">
                  <strong>Control Status</strong>
                </StyledTableCell>
                <StyledTableCell align="left">
                  {data?.control_status?.name}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow >
                <StyledTableCell component="th" scope="row">
                  <strong>Control Class</strong>
                </StyledTableCell>
                <StyledTableCell align="left">
                  {data?.control_class?.name}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow >
                <StyledTableCell component="th" scope="row">
                  <strong>Mitigation Percentage</strong>
                </StyledTableCell>
                <StyledTableCell align="left">
                  {data?.mitigation_percentage}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow >
                <StyledTableCell component="th" scope="row">
                  <strong>CAP / PO&AM (Plan of Action & Milestone) Assessment</strong>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Link href={`/projects/${data?.project?.id}/controls/${data?.id}/assessments/${data?.assessment?.id}`}>{data?.assessment?.name}</Link>
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow >
                <StyledTableCell component="th" scope="row">
                  <strong>CAP / PO&AM (Plan of Action & Milestone) Assessment Status</strong>
                </StyledTableCell>
                <StyledTableCell align="left">
                  {data?.assessment?.status}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow >
                <StyledTableCell component="th" scope="row">
                  <strong>CAP / PO&AM (Plan of Action & Milestone) Assessment Confirmed</strong>
                </StyledTableCell>
                <StyledTableCell align="left">
                  {data?.assessment?.is_assessment_confirmed}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow >
                <StyledTableCell component="th" scope="row">
                  <strong>Exception</strong>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Link href={`/projects/${data?.project?.id}/controls/${data?.id}/exceptions/${data?.exception?.id}`}>{data?.exception?.name}</Link>
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow >
                <StyledTableCell component="th" scope="row">
                  <strong>Keywords</strong>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <ul>
                    {(data?.keywords && (
                      data?.keywords.map((keyword) => {
                        return <li>
                          <Link href={`/keywords/${keyword?.keyword?.id}`}>{keyword?.keyword?.name}</Link>
                        </li>
                      })
                    )
                    )}
                  </ul>
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer></div>
          <div className='rightCol'>
            <h2>Documents</h2>
            <DocumentList documentListData={data?.documents} dataType={`project_controls`} dataId={data?.id} />
          </div></>}
        <Dialog
          //fullScreen
          open={editOpen}
          TransitionComponent={Transition}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          className={`dialog edit`}
        >
          <DialogActions className="create-edit-dialog">
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
          <DialogTitle>Edit {controlData?.control?.name}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <CreateEditProjectControl dataType='project_controls' editDataObj={data} projectId={projectId} controlData={addControlData} isOpen={isOpen} />
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
      <Dialog
        //fullScreen
        open={addExceptionOpen}
        TransitionComponent={Transition}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        className={`dialog edit`}
      >
        <DialogActions className="create-edit-dialog">
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
        <DialogTitle>Add Exception to {controlData?.control?.name}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <CreateEditProjectControlException dataType='project_controls' editDataObj={data} projectId={projectId} controlData={addControlData} isOpen={isOpen} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  )
}