import React, { useState, useEffect, useCallback } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import CreateEdit from '../components/CreateEditProjectAuditTest';
import SpinnerComponent from '../components/LoadingSpinner';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DocumentList from '../components/lists/DocumentList';
import CreateEditProjectCapPoam from "../components/CreateEditProjectCapPoam";
import AddCircleIcon from '@mui/icons-material/AddCircle';

/* This is a view for returning details on a project audit test */

export async function loadData(id) {
  //call data endpoint for data type to set rows and columns
  var url = `${process.env.REACT_APP_API_URL}/audit_tests/${id}`;
  const response = axios(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.access_token}`,
    },
  }).then(result => {
    return result;
  }).catch(error => { return error.message; })
  return response;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
/* This is a view for returning details on a project audit test data object */

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ProjectAuditTest({ id }) {
  const [data, setData] = useState([]);
  const [editOpen, setEditOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(null);

  //axios error message
  const [errMsg, setErrMsg] = useState("");
  useEffect(() => {
  }, [errMsg])
  const [isSystemAdmin, setIsSystemAdmin] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("system_role") === "4" || localStorage.getItem("system_role") === "1" || localStorage.getItem("system_role") === "2") {
      setIsSystemAdmin(true)
    }
  }, [setIsSystemAdmin])

  useEffect(() => {
  }, [isSystemAdmin])

  const handleClose = () => {
    setEditOpen(false);
    setOpenDialog(false);
  }

  const EditData = () => {
    setEditOpen(true);
  }

  const isOpen = () => {
    handleClose();
  }

  const addControlData = () => {
    getData()
  }

  const getData = useCallback(async () => {
    const jsonData = await loadData(id);
    if (jsonData.status === 200) {
      setData(jsonData.data);
      //console.log(`data = ${JSON.stringify(jsonData.data)}`)
    } else {
      setErrMsg(`${jsonData}`)
    }
  }, [id])

  useEffect(() => {
    if (data.length === 0) {
      getData();
    }
  }, [data, getData]);

  return (
    <>
      {errMsg ? (
        <Stack sx={{ width: '100%' }} spacing={2} className="alerts">
          <Alert severity="error">{errMsg}</Alert>
        </Stack>
      ) : (
        <></>
      )}
      <div className="details-view projectAuditTest">
        {(!data && <SpinnerComponent />)}
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/system-administration">
            System Administration
          </Link>
          <Link underline="hover" color="inherit" href={`/projects/list`}>
            Projects
          </Link>
          <Link underline="hover" color="inherit" href={`/projects/${data?.project?.id}`}>
            {data?.project?.name}
          </Link>
          <Link underline="hover" color="inherit" href={`/projects/${data?.project?.id}/audit_tests`}>
            Audit Tests
          </Link>
          <Typography color="text.primary">{data?.name}</Typography>
        </Breadcrumbs>
        <div className='leftCol'>
          {isSystemAdmin ? <Button variant="contained" onClick={EditData}>Edit</Button> : <></>}
          {data?.outcome_passed ?
            <Button variant="outlined"
              startIcon={<AddCircleIcon />}
              onClick={() => { setOpenDialog(true); }}
              className="addBtn">Add CAP / POAM</Button> :
            <></>
          }
          <>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableBody>
                  <StyledTableRow >
                    <StyledTableCell component="th" scope="row">
                      <strong>Name</strong>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {data?.name}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow >
                    <StyledTableCell component="th" scope="row">
                      <strong>Description</strong>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {data?.description}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow >
                    <StyledTableCell component="th" scope="row">
                      <strong>Keywords</strong>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <ul>
                        {(data?.keywords && (
                          data?.keywords.map((keyword) => {
                            return <li>
                              <Link href={`/keywords/${keyword.keyword.id}`}>{keyword.keyword.name}</Link>
                            </li>
                          })
                        )
                        )}
                      </ul>
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow >
                    <StyledTableCell component="th" scope="row">
                      <strong>Project Control</strong>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Link href={`/projects/${data?.project?.id}/controls/${data?.project_control?.id}`}>{data?.project_control?.control?.name}</Link>
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow >
                    <StyledTableCell component="th" scope="row">
                      <strong>Objective</strong>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {data?.objective}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow >
                    <StyledTableCell component="th" scope="row">
                      <strong>Approximate Time to Complete</strong>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {data?.approximate_time_to_complete}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow >
                    <StyledTableCell component="th" scope="row">
                      <strong>Expected Results</strong>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {data?.expected_results}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow >
                    <StyledTableCell component="th" scope="row">
                      <strong>Tester</strong>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Link href={`/users/${data?.tester?.id}`}>{data?.tester?.email}</Link>
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow >
                    <StyledTableCell component="th" scope="row">
                      <strong>Test Frequency</strong>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {data?.test_frequency}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow >
                    <StyledTableCell component="th" scope="row">
                      <strong>Last Test Date</strong>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {data?.last_test_date}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow >
                    <StyledTableCell component="th" scope="row">
                      <strong>Start Date</strong>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {data?.start_date}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow >
                    <StyledTableCell component="th" scope="row">
                      <strong>Due Date</strong>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {data?.due_date}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow >
                    <StyledTableCell component="th" scope="row">
                      <strong>Status</strong>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {data?.status}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow >
                    <StyledTableCell component="th" scope="row">
                      <strong>Outcome Passed</strong>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {data?.outcome_passed ? "Passed" : "Failed"}
                    </StyledTableCell>
                  </StyledTableRow>

                  {data?.outcome_passed &&
                    <StyledTableRow >
                      <StyledTableCell component="th" scope="row">
                        <strong>CAP / POAMS</strong>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <ul>
                          {(data?.cap_poams && (
                            data?.cap_poams.map((cap) => {
                              return <li>
                                <Link href={`/projects/${data?.project?.id}/cap_poams/${cap?.id}`}>{cap?.name}</Link>
                              </li>
                            })
                          )
                          )}
                        </ul>
                      </StyledTableCell>
                    </StyledTableRow>
                  }

                  <StyledTableRow >
                    <StyledTableCell component="th" scope="row">
                      <strong>Stakeholders</strong>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <ul>
                        {(data?.stakeholders && (
                          data?.stakeholders.map((stakeholder) => {
                            return <li>
                              <Link href={`/users/${stakeholder.id}`}>{stakeholder.email}</Link>
                            </li>
                          })
                        )
                        )}
                      </ul>
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </>
        </div>
        <div className='rightCol'>
          <h2>Documents</h2>
          <DocumentList documentListData={data?.documents} dataType={`audit_tests`} dataId={data?.id} />
        </div>
        <Dialog
          //fullScreen
          open={editOpen}
          TransitionComponent={Transition}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          className={`dialog edit`}
        >
          <DialogActions className="create-edit-dialog">
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
          <DialogTitle>Edit {data.name}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <CreateEdit editDataObj={data} projectId={data?.project?.id} isOpen={isOpen} controlData={addControlData} />
            </DialogContentText>
          </DialogContent>
        </Dialog>
        <Dialog
          //fullScreen
          open={openDialog ? openDialog : false}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "500px",  // Set your width here
              },
            },
          }}
          onClose={handleClose}
          aria-labelledby="add-project-data"
          aria-describedby="alert-dialog-add-project-data"
        >
          <DialogActions className="create-edit-dialog">
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
          <DialogTitle id="alert-dialog-title">
            Add cap poam
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <CreateEditProjectCapPoam isOpen={isOpen} controlData={addControlData} projectId={data?.project?.id} auTestId={data?.id}/>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    </>
  )
}