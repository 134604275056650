import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { Gantt } from "@dhx/gantt";
import Button from '@mui/material/Button';
import "@dhx/gantt/codebase/dhtmlxgantt.css";
import './Gantt.css';

export async function updateLinks(link) {
    var url = `${process.env.REACT_APP_API_URL}/tasks/${link.target}`;
    let targets = []
    let types = []
    targets.push(link.source)
    types.push(link.type)
    var body = JSON.stringify(
        {
            task_link_targets: targets,
            task_link_types: types,
        }
    )
    const response = axios(url, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.access_token}`,
        },
        data: body
    }).then(result => {
        return result;
    }).catch(error => { return error.message; })
    return response;
}

//get task data for dhtmlx
export async function getGanttChartData(wbsId) {

    var url = `${process.env.REACT_APP_API_URL}/tasks/dhwbsgantt/${wbsId}`;
    const response = axios(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.access_token}`,
        },
    }).then(result => {

        return result;
    }).catch(error => { return error.message; })

    return response;

}

//delete task
export async function deleteTask(task) {
    var url = `${process.env.REACT_APP_API_URL}/tasks/${task.id}`
    const response = axios(url, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.access_token}`,
        }
    }).then(result => {
        return result;
    }).catch(error => { return error.message; })
    return response;
}

export function to_snake_case(name) {
    return (name + "").toLowerCase().replace(/ /, "_");
}

var databaseId = 0
const gantt_container = <div className="gantt_here" id="gantt_here"></div>;
let gantt;
function GanttComponent() {

    const [ganttData, setGanttData] = useState(null);
    const [ganttResources, setGanttResources] = useState();

    const wbsId = window.location.pathname.split("/")[4];
    const projectId = window.location.pathname.split("/")[2];

    let loadType = "tasks";

    const getChartData = useCallback(async () => {
        const response = await getGanttChartData(wbsId);
        if (response?.data?.data[0]?.id != null) {
            setGanttData(response?.data)
            setGanttResources(response?.data?.resources)
        }

    }, [wbsId])

    useEffect(() => {
        if (ganttData === null) {
            getChartData()
        }
    }, [ganttData, getChartData])

    useEffect(() => {
    }, [ganttResources])

    const updateTask = useCallback(async (task, method, noFormatDate) => {
        //console.log('method', method)
        //console.log('task', task)
        let resources_value = []
        let resources = []
        if (task.owner) {
            task.owner.map((item) => {
                resources.push(item.resource_id)
                resources_value.push(item.value)
                return resources
            })
        }

        let milestone = false
        if (task.type === "milestone") {
            milestone = true
        }

        let start_date_string = ""
        let end_date_string = ""
        if (!noFormatDate) {
            let start_date_split = task.start_date.split("-")
            start_date_string = start_date_split[2].slice(0, 4) + "-" + start_date_split[1] + "-" + start_date_split[0]
            let end_date_split = task.end_date.split("-")
            end_date_string = end_date_split[2].slice(0, 4) + "-" + end_date_split[1] + "-" + end_date_split[0]
        }
        if (noFormatDate) {
            start_date_string = task.start_date
            end_date_string = task.end_date
        }

        //call data endpoint for data type to set rows and columns
        var url = `${process.env.REACT_APP_API_URL}/tasks/${task.id}`;
        if (method === "POST") {
            url = `${process.env.REACT_APP_API_URL}/tasks/task`;
        }
        var body;
        if (task.parent !== 0) {
            body = JSON.stringify(
                {
                    "title": task.text,
                    "name": task.text,
                    "project_id": projectId,
                    "comments": "Updated in WBS",
                    "due_date": end_date_string,
                    "actual_start_date": start_date_string,
                    "actual_end_date": end_date_string,
                    "duration": task.duration,
                    "percent_complete": task.progress,
                    "milestone": milestone,
                    "assigned_to": task.assigned,
                    "estimated_loe": task.estimated_loe,
                    "actual_loe": task.actual_loe,
                    "estimated_cost": task.estimated_cost,
                    "actual_cost": task.actual_cost,
                    //"child_task_order": task.order,
                    "parents": [task.parent],
                    "wbs_id": wbsId,
                    "user_id": localStorage.getItem("userid"),
                    "resources": resources,
                    "resources_value": resources_value
                }
            )
        } else {
            body = JSON.stringify(
                {
                    "title": task.text,
                    "name": task.text,
                    "project_id": projectId,
                    "comments": "Updated in WBS",
                    "due_date": end_date_string,
                    "actual_start_date": start_date_string,
                    "actual_end_date": end_date_string,
                    "duration": task.duration,
                    "percent_complete": task.progress,
                    "milestone": milestone,
                    "assigned_to": task.assigned,
                    "estimated_loe": task.estimated_loe,
                    "actual_loe": task.actual_loe,
                    "estimated_cost": task.estimated_cost,
                    "actual_cost": task.actual_cost,
                    "wbs_id": wbsId,
                    "user_id": localStorage.getItem("userid"),
                    "resources": resources,
                    "resources_value": resources_value
                }
            )
        }
        const response = axios(url, {
            method: method,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.access_token}`,
            },
            data: body
        }).then(result => {
            return result;
        }).catch(error => { return error.message; })
        return response;
    }, [projectId, wbsId])

    useEffect(() => {

        gantt = Gantt.getGanttInstance();
        //console.log("Gantt", Gantt)

        gantt.plugins({
            click_drag: true
        });
        gantt.plugins({
            export_api: true
        });

        gantt.config.columns = [
            { name: "text", tree: true, width: 200, resize: true },
            { name: "start_date", align: "center", width: 80, resize: true },
            {
                name: "owner", align: "center", width: 75, label: "Owner", template: function (task) {
                    if (task.type === gantt.config.types.milestone) {
                        return "";
                    }

                    const store = gantt.getDatastore("resource");
                    const assignments = task[gantt.config.resource_property];

                    if (!assignments || !assignments.length) {
                        return "Unassigned";
                    }

                    if (assignments.length === 1) {
                        return store.getItem(assignments[0].resource_id).text;
                    }

                    let result = "";
                    assignments.forEach(function (assignment) {
                        const owner = store.getItem(assignment.resource_id);
                        if (!owner)
                            return;
                        result += "<div className='owner-label' title='" + owner.text + "'>" + owner.text.substr(0, 1) + "</div>";

                    });

                    return result;
                }, resize: true
            },
            { name: "duration", width: 60, align: "center" },
            { name: "add", width: 44 }
        ];

        const resourceConfig = {
            columns: [
                {
                    name: "name", label: "Name", tree: true, template: function (resource) {
                        return resource.text;
                    }
                },
                {
                    name: "workload", label: "Workload", template: function (resource) {
                        let tasks;
                        const store = gantt.getDatastore(gantt.config.resource_store),
                            field = gantt.config.resource_property;



                        if (store.hasChild(resource.id)) {
                            tasks = gantt.getTaskBy(field, store.getChildren(resource.id));
                        } else {
                            tasks = gantt.getTaskBy(field, resource.id);
                        }
                        //console.log(tasks)

                        let totalDuration = 0;
                        for (let i = 0; i < tasks.length; i++) {
                            totalDuration += tasks[i].duration;
                        }

                        return (totalDuration || 0) * 8 + "h";
                    }
                }
            ],
            scales: [
                { unit: "day", step: 1, date: "%d %M" }
            ],
        };

        gantt.templates.resource_cell_class = function (start_date, end_date, resource, tasks) {
            const css = [];
            css.push("resource_marker");
            if (tasks.length <= 1) {
                css.push("workday_ok");
            } else {
                css.push("workday_over");
            }
            return css.join(" ");
        };

        gantt.templates.resource_cell_value = function (start_date, end_date, resource, tasks) {
            let result = 0;
            tasks.forEach(function (item) {
                const assignments = gantt.getResourceAssignments(resource.id, item.id);
                assignments.forEach(function (assignment) {
                    result += assignment.value
                })
            });

            if (result % 1) {
                result = Math.round(result * 10) / 10;
            }
            return "<div>" + result + "</div>";
        };

        gantt.locale.labels.section_owner = "Owner";
        gantt.config.lightbox.sections = [
            { name: "description", height: 38, map_to: "text", type: "textarea", focus: true },
            {
                name: "resources", type: "resources", map_to: "owner", options: gantt.serverList("people"), default_value: 8
            },
            { name: "time", type: "duration", map_to: "auto" }
        ];

        gantt.config.resource_store = "resource";
        gantt.config.resource_property = "owner";
        gantt.config.order_branch = true;
        gantt.config.open_tree_initially = true;

        gantt.config.work_time = true;
        gantt.config.dynamic_resource_calendars = true;

        gantt.config.resource_calendars = {
            6: gantt.addCalendar({
                id: 6,
                worktime: {
                    days: [0, 1, 0, 1, 0, 1, 0]
                }
            }),
            8: gantt.addCalendar({
                id: 8,
                worktime: {
                    days: [1, 0, 0, 1, 0, 0, 1]
                }
            }),
            10: gantt.addCalendar({
                id: 10,
                worktime: {
                    days: [0, 1, 1, 1, 0, 1, 1]
                }
            })
        };

        gantt.templates.timeline_cell_class = function (item, date) {
            // Gantt timeline
            if (item.$source) {
                if (!gantt.isWorkTime({ date: date, task: item })) {
                    return "weekend";
                }
                return "";
            }

            // Resource timeline
            const calendar = gantt.getCalendar(item.id) || gantt.getCalendar("global");
            if (!calendar.isWorkTime({ date: date })) {
                return "weekend";
            }
        };

        var resourceTemplates = {
            grid_row_class: function (start, end, resource) {
                var css = [];
                //if(gantt.$resourcesStore.hasChild(resource.id)){
                css.push("folder_row");
                css.push("group_row");
                //}

                return css.join(" ");
            },
            task_row_class: function (start, end, resource) {
                var css = [];

                //if(gantt.$resourcesStore.hasChild(resource.id)){
                css.push("group_row");
                //}

                return css.join(" ");

            }
        };

        gantt.config.layout = {
            css: "gantt_container",
            rows: [
                {
                    cols: [
                        { view: "grid", group: "grids", scrollY: "scrollVer" },
                        { resizer: true, width: 1 },
                        { view: "timeline", scrollX: "scrollHor", scrollY: "scrollVer" },
                        { view: "scrollbar", id: "scrollVer", group: "vertical" }
                    ],
                    gravity: 2
                },
                { resizer: true, width: 1 },
                {
                    config: resourceConfig,
                    templates: resourceTemplates,
                    cols: [
                        { view: "resourceGrid", group: "grids", width: 435, scrollY: "resourceVScroll" },
                        { resizer: true, width: 1 },
                        { view: "resourceTimeline", scrollX: "scrollHor", scrollY: "resourceVScroll" },
                        { view: "scrollbar", id: "resourceVScroll", group: "vertical" }
                    ],
                    gravity: 1
                },
                { view: "scrollbar", id: "scrollHor" }
            ]
        };

        const resourcesStore = gantt.createDatastore({
            name: gantt.config.resource_store,
            type: "treeDatastore",
            initItem: function (item) {
                item.parent = item.parent || gantt.config.root_id;
                item[gantt.config.resource_property] = item.parent;
                item.open = true;
                return item;
            }
        });

        resourcesStore.attachEvent("onParse", function () {
            const people = [];
            resourcesStore.eachItem(function (res) {
                if (!resourcesStore.hasChild(res.id)) {
                    let copy = gantt.copy(res);
                    copy.key = res.id;
                    copy.label = res.text;
                    people.push(copy);
                }
            });
            gantt.updateCollection("people", people);
        });
        if (ganttResources) {
            resourcesStore.parse(ganttResources)
        }

        gantt.createDataProcessor((type, action, item, id) => {
            return new Promise((resolve, reject) => {
                //taskIds.push(id)
                // console.log(`type ${type}`)
                // console.log(`action ${action}`)
                // console.log(`item ${JSON.stringify(item)}`)
                // console.log(`id ${id}`)

                var method = "PUT"
                if (action === "create") {
                    //console.log("i am creating")
                    if (item["source"]) {
                        updateLinks(item)
                        //gantt.destructor()
                        gantt.parse(getGanttChartData())
                    }
                    if (!item["source"] && databaseId === 0) {
                        method = "POST"
                        updateTask(item, method).then(window.location.reload(false))

                        // databaseId = response.data.id
                        // window.location.reload(false)
                        // //item.id = databaseId
                        // id = databaseId
                        // gantt.clearAll().then(gantt.parse(getGanttChartData()))
                        // gantt.parse(getGanttChartData())
                    } else {
                        method = "PUT"
                        let response = updateTask(item, method)
                        databaseId = response.data.id
                        //item.id = databaseId
                        id = databaseId
                        gantt.clearAll().then(gantt.parse(getGanttChartData()))
                        gantt.parse(getGanttChartData())
                    }

                }
                if (action === "update") {
                    let response = updateTask(item, method)
                    databaseId = response.data.id
                    //gantt.destructor()
                    gantt.parse(getGanttChartData())
                }
                if (action === "delete") {
                    deleteTask(item).then(gantt.deleteTask(id))
                    gantt.destructor()
                }
                //onDataUpdated(type, action, item, id)

                // if onDataUpdated changes returns a permanent id of the created item, you can return it from here so dhtmlxGantt could apply it
                return resolve({ id: databaseId });
                //return resolve();
            });
        });

        gantt.init("gantt_here");

        if (ganttData) {
            gantt.parse(ganttData)
        }

        return () => {
            gantt.destructor()
        }

    }, [ganttResources, ganttData, updateTask])

    const exportGanttToExcel = useCallback(async () => {

        /*const tasks = await getGanttChartData()
        const data = {
            "data": tasks.tasks
        }*/

        gantt.exportToExcel({
            visual: true,
            cellColors: true,
            name: "fedriskwbs.xlsx",
            data: ganttData?.data
        })

    }, [ganttData?.data])

    const exportGanttToiCal = useCallback(() => {
        gantt.exportToICal()
    }, [])

    const exportGanttToMSProject = useCallback(() => {
        gantt.exportToMSProject()
    }, [])

    const exportGanttToPrimavera = useCallback(() => {
        gantt.exportToPrimaveraP6()
    }, [])

    const exportGanttToPDF = useCallback(() => {
        gantt.exportToPDF()
    }, [])

    function convertDateString(datetimestamp) {
        let timeStamp = Date.parse(datetimestamp);
        var date = new Date(timeStamp).toLocaleDateString;
        return date
    }
    function loadTable(mapping, data) {
        const ganttDataset = {
            tasks: [],
            links: []
        };

        data.forEach(function (item) {
            const copy = {};
            for (let i in item) {
                if (mapping[i]) {
                    copy[mapping[i]] = item[i];
                } else {
                    copy[to_snake_case(i)] = item[i];
                }

                copy.open = true;
                if (copy.wbs) {
                    const wbs = copy.wbs + "";
                    copy.id = wbs;
                    const parts = wbs.split(".");
                    parts.pop();
                    copy.parent = parts.join(".");
                }
            }
            ganttDataset[loadType].push(copy);
        });

        gantt.parse(ganttDataset);
        //save to database
        ganttDataset.tasks.forEach(function (item) {
            let startDate = convertDateString(item.start_date)
            let endDate = convertDateString(item.end_date)
            let taskObj = {
                "text": item.text,
                "start_date": startDate,
                "end_date": endDate,
                "resource_id": localStorage.getItem("userid"),
                "parent": item.parent,
                "duration": item.duration,
            }
            updateTask(taskObj, "POST", true)
        })


    }

    function getOptions(selectedIndex) {

        const columns = {
            tasks: [
                "id", "text", "start_date", "duration", "end_date", "parent", "wbs", "owner"
            ],
            links: [
                "id", "source", "target", "type", "lag"
            ]
        }

        return columns[loadType].map(function (name, index) {
            return "<option value='" + name + "' " + (selectedIndex === index ? "selected" : "") + ">" + name + "</option>";
        }).join("");
    }

    function upload(file, callback) {
        gantt.importFromExcel({
            server: "https://export.dhtmlx.com/gantt",
            data: file,
            callback: function (project) {
                if (project) {

                    const header = [];
                    const headerControls = [];
                    const body = [];


                    project.forEach(function (task) {
                        let cols = [];
                        if (!header.length) {
                            for (let i in task) {
                                header.push(i);
                            }
                            header.forEach(function (col, index) {
                                cols.push("<th>" + col + "</th>");
                                headerControls.push("<td><select data-column-mapping='" + col + "'>" + getOptions(index) + "</select>")
                            });
                            body.push("<tr>" + cols.join("") + "</tr>");
                            body.push("<tr>" + headerControls.join("") + "</tr>");
                        }
                        cols = [];
                        header.forEach(function (col) {
                            cols.push("<td>" + task[col] + "</td>");
                        });
                        body.push("<tr>" + cols.join("") + "</tr>");
                    });


                    const div = gantt.modalbox({
                        title: "Assign columns",
                        type: "excel-form",
                        text: "<table>" + body.join("") + "</table>",
                        buttons: [
                            { label: "Save", css: "link_save_btn", value: "save" },
                            { label: "Cancel", css: "link_cancel_btn", value: "cancel" }
                        ],
                        callback: function (result) {
                            switch (result) {
                                case "save":
                                    const selects = div.querySelectorAll("[data-column-mapping]");
                                    const mapping = {};
                                    selects.forEach(function (select) {
                                        mapping[select.getAttribute("data-column-mapping")] = select.value;
                                    });
                                    loadTable(mapping, project);
                                    break;
                                case "cancel":
                                    //Cancel
                                    break;
                                default:
                                    break;
                            }
                        }
                    });



                }

                if (callback)
                    callback(project);
            }
        });
    }

    function uploadFile() {
        gantt.confirm({
            text: "Are we loading tasks?",
            ok: "Yes, tasks",
            cancel: "No, links",
            callback: function (result) {
                if (result) {
                    loadType = "tasks"
                }
                if (!result) {
                    loadType = "links"
                }
                const fileInput = document.getElementById("excelFile");
                //console.log('uploadFile: ', fileInput.files[0]);
                upload(fileInput.files[0]);

            }
        });

    }

    return (
        <>
            <Button onClickCapture={() => { exportGanttToExcel(); }}>Export to Excel</Button>
            <Button onClickCapture={() => { exportGanttToiCal(); }}>Export to iCal</Button>
            <Button onClickCapture={() => { exportGanttToMSProject(); }}>Export to MSProject</Button>
            <Button onClickCapture={() => { exportGanttToPrimavera(); }}>Export to Primavera6</Button>
            <Button onClickCapture={() => { exportGanttToPDF(); }}>Export to PDF</Button>
            <form id="mspImport" action="" method="POST" enctype="multipart/form-data">
                <input type="file" id="excelFile" name="file" accept=".xlsx,.xls" data-testid="upInput" />
            </form>
            <button type="button" className="btn btn-sm btn-primary" onClick={() => { uploadFile(); }}><i className="fa fa-upload">Load from Excel</i></button>

            <div className="ganttChartContainer">{gantt_container}</div>
        </>

    )
}

export default GanttComponent;