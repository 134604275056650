//import React, {Suspense} from 'react';
import React from 'react';
//import SpinnerComponent from '../components/LoadingSpinner';
import TaskList from '../components/lists/TaskList';
import TaskCalendar from '../components/TaskCalendar';
// const TaskList = lazy(() => import('../components/lists/TaskList'));
// const TaskCalendar = lazy(() => import('../components/TaskCalendar'));

export default function Tasks() {
  return (
    <>
      <h1>Tasks</h1>
      <p>Alert users to upcoming tasks</p>
      <div className="panel calendar">
        <h2>Task Calendar</h2>
        { /* <Suspense fallback={<SpinnerComponent />}><TaskCalendar /></Suspense> */}
        <TaskCalendar />
      </div>
      <div className="panel">
        { /* <Suspense fallback={<SpinnerComponent />}><TaskList /></Suspense> */}
        <TaskList />
      </div>
    </>
  );
}