import React, { useState, useEffect } from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import Link from '@mui/material/Link';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import '../css/Header.css';
import HeaderSearch from './HeaderSearch';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import HelpIcon from '@mui/icons-material/Help';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import ChatIcon from '@mui/icons-material/Chat';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import Chatbot from 'react-chatbot-kit';
import 'react-chatbot-kit/build/main.css';
import config from '../chatbot/config.js'
import MessageParser from '../chatbot/MessageParser.js'
import ActionProvider from '../chatbot/ActionProvider.js'
import { useSelector, useDispatch } from 'react-redux'
import { selectAllNotifications, fetchNotifications } from '../features/notifications/notificationsSlice'
import SpinnerComponent from './LoadingSpinner';


function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

export default function Header() {

  const dispatch = useDispatch()
  const notificationsList = useSelector(selectAllNotifications)

  const notificationStatus = useSelector(state => state.notifications.status)
  const error = useSelector((state) => state.notifications.error)

  useEffect(() => {
    if (notificationStatus === 'idle') {
      dispatch(fetchNotifications())
    }
  }, [notificationStatus, dispatch])

  useEffect(() => {
  }, [notificationsList])

  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [notificationsAnchorEl, setNotificationsAnchorEl] = useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const isNotificationsOpen = Boolean(notificationsAnchorEl);

  const [isBillingAdmin, setIsBillingAdmin] = useState(false)

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleNotificationsClose = () => {
    setNotificationsAnchorEl(null);
  };

  const handleNotificationsOpen = (event) => {
    setNotificationsAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    handleNotificationsClose();
  };

  const logOut = () => {
    localStorage.clear();
    window.location.reload(false);
  }

  useEffect(() => {
    if (localStorage.getItem("system_role") === "5") {
      setIsBillingAdmin(true)
    }

  }, [setIsBillingAdmin])
  let content

  if (notificationStatus === 'loading') {
    content = <SpinnerComponent text="Loading..." />
  } else if (notificationStatus === 'succeeded') {

    content = notificationsList.map((notification) => (
      <Link href={notification.notification_data_path} key={notification.notification_data_path}>{notification.notification_message}</Link>
    ))
  } else if (notificationStatus === 'failed') {
    content = <div>{error}</div>
  }

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >

      <Link href={`/users/${localStorage.getItem("userid")}`} underline="none"><MenuItem onClick={handleMenuClose}>Profile</MenuItem></Link>
      <Link href='/' underline="none"><MenuItem onClick={() => { handleMenuClose(); logOut(); }}>Log out</MenuItem></Link>
    </Menu>
  );

  const popoverId = 'primary-notifications-account-menu'
  const renderNotificationsPopover = (
    <Popover
      className='notifications-popover'
      key={popoverId}
      id={popoverId}
      open={isNotificationsOpen}
      anchorEl={notificationsAnchorEl}
      onClose={handleNotificationsClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      {content}
      <Link href="/notifications/all"><Button>View all</Button></Link>
    </Popover>
  )

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show new notifications"
          color="inherit"
        >
          <Badge badgeContent={notificationsList.length} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
      <Link href='/' underline="none"><MenuItem onClick={() => { handleMenuClose(); logOut(); }}>Log out</MenuItem></Link>
    </Menu>
  );


  return (
    <>
      <Box sx={{ flexGrow: 1 }} className='header'>
        <AppBar position="static">
          <Toolbar>
            <VerifiedUserIcon />
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ display: { xs: 'none', sm: 'block' } }}
            >
              FedRisk
            </Typography>
            {!isBillingAdmin && <> <Search>
              <HeaderSearch />
            </Search></>}
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              <Tooltip title="Chat">
                <IconButton
                  size="large"
                  edge="end"
                  aria-label="chat"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleClickOpen}
                  color="inherit"
                >
                  <ChatIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Help and Support">
                <IconButton
                  size="large"
                  edge="end"
                  aria-label="help and support"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  href='/help'
                  //onClick={handleClickOpen}
                  color="inherit"
                >
                  <HelpIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Notifications">
                <IconButton
                  size="large"
                  aria-label="show new notifications"
                  color="inherit"
                  aria-haspopup="true"
                  onClick={handleNotificationsOpen}
                >
                  <Badge badgeContent={notificationsList.length} color="error">
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
              </Tooltip>
              <Tooltip title="Profile and Log Out">
                <IconButton
                  size="large"
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
              </Tooltip>
            </Box>
            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
        {renderMenu}
        {renderNotificationsPopover}
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="chat-icon"
      >
        <DialogTitle style={{ cursor: 'move' }} id="chat-icon">
          Chat
        </DialogTitle>
        <DialogContent>
          <Chatbot
            config={config}
            messageParser={MessageParser}
            actionProvider={ActionProvider}
          />

        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}