import React, { useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ReactApexChart from 'react-apexcharts'
import '../../css/CapPoamComplianceCharts.css'

/* Used for the Compliance dashboard */

const chartDataByMonth = {
  series: [
    {
      name: 'CAP / POAMs',
      data: [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380, 344, 566]
    }
  ],
  dataLabels: {
    enabled: true,
    textAnchor: 'top',
    style: {
      colors: ['#ffffff']
    },
    background: {
      enabled: true,
      foreColor: '#007EB3',
      borderRadius: 2,
      padding: 4,
      opacity: 0.9,
      borderWidth: 1,
      borderColor: '#007EB3'
    },
    /*formatter: function (value) {
      return value + ' 100%';
    }*/
  },
  options: {
    chart: {
      height: 520,
      width: 800,
      type: 'bar'
    },
    plotOptions: {
      bar: {
        barHeight: '100%',
        distributed: true,
        vertical: true,
        dataLabels: {
          position: 'bottom'
        },
      }
    },
    colors: ['#0074A6', '#868A96'
    ],

    stroke: {
      width: 1,
      colors: ['#fff']
    },
    xaxis: {
      categories: ['January', 'February', 'March', 'April', 'May', 'June', 'July',
        'August', 'September', 'October', 'November', 'December'
      ],
    },
    yaxis: {
      labels: {
        show: false
      }
    },

  },
};

const chartDataDummy = {
  series: [
    {
      name: 'Actual',
      data: [
        {
          x: 'Not Started',
          y: 12
        },
        {
          x: 'Completed',
          y: 54
        },
        {
          x: 'In Progress',
          y: 66
        }
      ]
    }
  ],
  options: {
    chart: {
      height: 350,
      width: 250,
      type: 'bar'
    },
    plotOptions: {
      bar: {
        horizontal: true,
      }
    },
    colors: ['#0074A6']
  },
};


const dummyoptions = {
  chart: {
    type: 'bar',
  },
  plotOptions: {
    bar: {
      horizontal: true,
      columnWidth: '40%',
      barHeight: '20%',
      dataLabels: {
        position: 'top',
        hideOverflowingLabels: true,
        orientation: 'horizontal',
      }
    },
  },
  dataLabels: {
    enabled: true,
    textAnchor: 'start',
    style: {
      colors: ['#ffffff']
    },
    background: {
      enabled: true,
      foreColor: '#007EB3',
      borderRadius: 2,
      padding: 4,
      opacity: 0.9,
      borderWidth: 1,
      borderColor: '#007EB3'
    },
    /*formatter: function (value) {
      return value + ' 100%';
    }*/
  },
  colors: ['#007EB3'],
}

const dummyoptionsCrit = {
  chart: {
    type: 'bar',
  },
  plotOptions: {
    bar: {
      horizontal: true,
      columnWidth: '40%',
      barHeight: '20%',
      dataLabels: {
        position: 'top',
        hideOverflowingLabels: true,
        orientation: 'horizontal',
      }
    },
  },
  dataLabels: {
    enabled: true,
    textAnchor: 'start',
    style: {
      colors: ['#ffffff'],
    },
    background: {
      enabled: true,
      foreColor: '#007EB3',
      borderRadius: 2,
      padding: 4,
      opacity: 0.9,
      borderWidth: 1,
      borderColor: '#007EB3'
    },
    /*formatter: function (value) {
      return value + ' 100%';
    }*/
  },
  colors: ['#339900', '#FF6600', '#990000'],
}

const CapPoamsComplianceCharts = ({ capPoamsByMonth, capPoamsByStatus, capPoamsByCriticality, project }) => {

  useEffect(() => {
  }, [capPoamsByMonth, capPoamsByStatus, capPoamsByCriticality, project]);

  return (
    <div className='project cappoams charts'>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableBody>
            <TableRow
              key='project'
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left" className='title'>Project</TableCell>
              <TableCell align="left"><a href={`/projects/${project?.id}`}>{project ? project?.name : <></>}</a></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <div className="row1">
        <div className="compliance-cap-poam-status">
          <h2>Status</h2>
          <ReactApexChart
            options={dummyoptions}
            series={capPoamsByStatus?.series ? capPoamsByStatus?.series : chartDataDummy.series}
            type="bar"
          />
        </div>
        <div className="compliance-cap-poam-criticality">
          <h2>Criticality</h2>
          <ReactApexChart
            options={dummyoptionsCrit}
            series={capPoamsByCriticality?.series ? capPoamsByCriticality?.series : chartDataDummy.series}
            type="bar"
          />
        </div>
      </div>
      <div className="compliance-cap-poam-month">
        <h2>Count of CAP / POAMs by Due Date Month</h2>
        <ReactApexChart
          options={chartDataByMonth.options}
          series={capPoamsByMonth?.series ? capPoamsByMonth?.series : chartDataDummy.series}
          type="bar"
        />
      </div>

    </div>
  )
}

export default CapPoamsComplianceCharts;