import React, { useState, useEffect, useMemo, useCallback, lazy, Suspense } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EngineeringIcon from '@mui/icons-material/Engineering';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ProjectWizard from '../components/ProjectWizard';
import Subscription from './Subscription';
import axios from 'axios';
import { useForm, Controller } from "react-hook-form";
import { MenuItem, Select } from '@mui/material';
import SpinnerComponent from '../components/LoadingSpinner';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import '../css/Main.css'
const RiskList = lazy(() => import('../components/lists/RiskList'));
const ProjectControlList = lazy(() => import('../components/lists/ProjectControlList'));
const AuditTestList = lazy(() => import('../components/lists/AuditTestList'));
const ProjectSummaryCharts = lazy(() => import('../components/graphs/ProjectSummaryCharts'));

/* 
********START all endpoints called
*/

export async function loadRiskData (projectId) {
  //call data endpoint for data type to set rows and columns
  var url = `${process.env.REACT_APP_API_URL}/risks/tasks/?project_id=${projectId}`;
  const response = await axios(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.access_token}`,
    },
  }).then(result => {
    return result;
  }).catch(error => { return error.message; })
  return response;
}

export async function getData (projectId) {
  var url = `${process.env.REACT_APP_API_URL}/summary_dashboards/${projectId}`;
  const response = await axios(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.access_token}`,
    },
  }).then(result => {
    return result;
  }).catch(error => { return error.message; })
  return response;
}

export async function loadProjectControlData (projectId) {
  //call data endpoint for data type to set rows and columns
  var url = `${process.env.REACT_APP_API_URL}/projects/project_controls/${projectId}?offset=0&limit=1500`;
  const response = axios(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.access_token}`,
    },
  }).then(result => {
    return result;
  }).catch(error => { return error.message; })
  return response;
}

export async function loadAuditTestData (projectId) {
  //call data endpoint for data type to set rows and columns
  var url = `${process.env.REACT_APP_API_URL}/projects/audit_tests/${projectId}`;
  const response = await axios(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.access_token}`,
    },
  }).then(result => {
    return result;
  }).catch(error => { return error.message; })
  return response;
}

export async function getProjectData () {
  var url = `${process.env.REACT_APP_API_URL}/projects/get_user_projects/${localStorage.getItem("userid")}`
  if (localStorage.getItem("system_role") === "4") {
    url = `${process.env.REACT_APP_API_URL}/projects/tenant/`
  }

  const response = await axios(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.access_token}`,
    },
  }).then(result => {
    return result;
  }).catch(error => { return error.message; })
  return response;
}

/* 
********END All endpoints called
*/

export default function Main() {
  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  }));

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));
  const [openProjectWizardDialog, setOpenProjectWizardDialog] = useState(false);
  const [isBillingAdmin, setIsBillingAdmin] = useState(false);
  //default option when no value selected in drop down
  const selectOption = useMemo(() => { return { value: "0", label: "Please select..." } }, [])
  const [projects, setProjects] = useState([{ value: "0", label: "Please select..." }]);
  const [projectId, setProjectId] = useState();
  const [chartData, setChartData] = useState([]);
  const [riskListData, setRiskListData] = useState(null);
  const [projectControlListData, setProjectControlListData] = useState(null);
  const [auditTestListData, setAuditTestListData] = useState(null);
  const [controlStatusData, setControlStatusData] = useState([]);
  const [exceptionStatusData, setExceptionStatusData] = useState();
  const [scheduledAuditsData, setScheduledAuditsData] = useState();
  const [isSystemAdmin, setIsSystemAdmin] = useState(null);
  const [expanded, setExpanded] = useState('panel1');

  useEffect(() => {
    if (localStorage.getItem("system_role") === "4" ||
      localStorage.getItem("system_role") === "1"
    ) {
      setIsSystemAdmin(true)
    } else {
      setIsSystemAdmin(false)
    }

  }, [setIsSystemAdmin])

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const { control } = useForm({
    defaultValues: {
      project: {},
    }
  });

  const handleClose = () => {
    setOpenProjectWizardDialog(false);
    //window.location.reload(false);
  }

  const isOpen = () => {
    handleClose();
  }

  const handleChangeProjectSelect = (event) => {
    setRiskListData(null)
    setProjectControlListData(null)
    setAuditTestListData(null)
    setProjectId(event.target.value)
    getChartData(event.target.value)
    getRiskData(event.target.value)
    getProjectControlData(event.target.value)
    getAuditTestData(event.target.value)
  };

  useEffect(() => {

  }, [projectId])

  useEffect(() => {

  }, [auditTestListData])

  useEffect(() => {
  }, [projectControlListData])

  useEffect(() => {

  }, [riskListData])

  useEffect(() => {
  }, [projects])

  useEffect(() => {
  }, [controlStatusData])

  const getChartData = useCallback(async (projId) => {
    const jsonData = await getData(projId);
    if (jsonData === 'Request failed with status code 401') {
      localStorage.clear();
      window.location.reload(false);
    }
    var assessments_complete_percent = jsonData.data?.assessments_complete / jsonData.data?.total_assessments * 100
    const control_status_data_graph = {
      series:
        [
          {
            name: 'Actual',
            data: jsonData.data.control_status_data,
          }
        ]
    }
    setControlStatusData(control_status_data_graph)

    const exception_status_data_graph = {
      series:
        [
          {
            name: 'Actual',
            data: jsonData.data.exceptions_status_data,
          }
        ]
    }

    setExceptionStatusData(exception_status_data_graph)
    var auditData = []
    await jsonData.data.scheduled_audits_data.forEach((data) => {
      auditData.push({
        x: data.x,
        y: [new Date(data.y[0]).getTime(), new Date(data.y[1]).getTime()]
      })
    })
    const scheduled_audits_data_graph = {
      series:
        [
          {
            data: auditData,
          }
        ]
    }
    
    setScheduledAuditsData(scheduled_audits_data_graph)
    var tmpData = []
    tmpData.push(
      {
        series: [
          jsonData.data?.high_risks,
          jsonData.data?.medium_high_risks,
          jsonData.data?.medium_risks,
          jsonData.data?.low_medium_risks,
          jsonData.data?.low_risks
        ],
        num_risks_over_5: jsonData.data?.num_risks_over_5,
        projectname: jsonData.data?.name,
        projectid: jsonData.data?.id,
        total_controls: jsonData.data?.controls,
        incomplete_audit_tests: jsonData.data?.incomplete_audit_tests,
        audit_test_pending: jsonData.data?.audit_tests_pending,
        audit_test_completed: jsonData.data?.audit_tests_completed,
        total_assessments: jsonData.data?.total_assessments,
        percent_assessments_completed: assessments_complete_percent,
        //total_exceptions: project?.project?.total_exceptions,
        create_date: jsonData.data?.created_date,
        last_update_date: jsonData.data?.last_updated,
        user_id: jsonData.data?.project_administrator_id,
        email: jsonData.data?.project_administrator_email,
        frameworks: jsonData.data?.frameworks,
        //control_status_data: control_status_data_graph,
      }
    )
    setChartData(tmpData);
  }, [])

  const getRiskData = useCallback(async (projectId) => {
    const jsonData = await loadRiskData(projectId);
    if (jsonData === 'Request failed with status code 401') {
      localStorage.clear();
      window.location.reload(false);
    }
    setRiskListData(jsonData)
  }, [])

  const getProjectControlData = useCallback(async (projectId) => {
    const jsonData = await loadProjectControlData(projectId);
    if (jsonData === 'Request failed with status code 401') {
      localStorage.clear();
      window.location.reload(false);
    }
    setProjectControlListData(jsonData)
  }, [])

  const getAuditTestData = useCallback(async (projectId) => {
    const jsonData = await loadAuditTestData(projectId);
    if (jsonData === 'Request failed with status code 401') {
      localStorage.clear();
      window.location.reload(false);
    }
    setAuditTestListData(jsonData)
  }, [])

  const getDropDownOptions = useCallback(async () => {
    const jsonData = await getProjectData();
    if (jsonData === 'Request failed with status code 401') {
      localStorage.clear();
      window.location.reload(false);
    }
    if (jsonData?.data) {
      const projectOptions = jsonData?.data?.map((project) => ({
        value: project.id,
        label: project.name,
      }));
      setProjects([selectOption, ...projectOptions])
    }

  }, [selectOption])

  useEffect(() => {
    if (localStorage.getItem("system_role") === "5") {
      setIsBillingAdmin(true)
    }
  }, [setIsBillingAdmin])

  useEffect(() => {

  }, [isBillingAdmin])

  useEffect(() => {
    if (localStorage.getItem("system_role") !== null) {
      getDropDownOptions();
    }
  }, [getDropDownOptions]);

  if (isBillingAdmin) {
    return (
      <Subscription />
    )
  }

  return (
    <>
      <h1 className="summmaryDash">Main Summary Dashboard</h1>
      {isSystemAdmin && 
      <div className="project-wizard-card">
        <div className="left-card-wizard">
          <h2><TextSnippetIcon />Select a Project</h2>
          <div className="form-field control project dropdown">
          <Controller
            name="project"
            control={control}
            defaultValue={"0"}
            render={({ field }) =>
              <Select
                {...field}
                onChange={handleChangeProjectSelect}
                value={projectId ? projectId : "0"}
                defaultValue={"0"}
                className="selectAProject"
                inputProps={{ "data-testid": "project-select" }}
              >
                {
                  projects?.map((project) => {
                    return <MenuItem
                      value={project.value}
                      key={project.value}>
                      {project.label}
                    </MenuItem>
                  })
                }
              </Select>
            }
          />
        </div>




        </div>
        <div className="right-card-wizard">
            <h2><EngineeringIcon />Start a Project</h2>
            {/* <p>Use our Step-by-Step Guided Process</p> */}
          
            <Button className="projectWizardBtn" aria-label="project-wizard" onClick={() => { setOpenProjectWizardDialog(true); }} startIcon={<AddCircleIcon />}>
              Quick Start Wizard
            </Button>
          
        </div>
        </div>}

      {/*<form onSubmit={handleSubmit()}> */}
        
      {/* </form> */}
      <h2 className="projectSnap">Project Snapshot</h2>
      <Suspense fallback={<SpinnerComponent />}><ProjectSummaryCharts projectData={chartData} controlStatusData={controlStatusData} exceptionStatusData={exceptionStatusData} scheduledAuditsData={scheduledAuditsData} /></Suspense>
      {projectId && riskListData && auditTestListData && <>
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography>Governance</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="panel 1">
              {projectControlListData !== null && <Suspense fallback={<SpinnerComponent />}><ProjectControlList projectId={projectId} projectControlData={projectControlListData} /></Suspense>}
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
            <Typography>Risks</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="panel 2">
              {riskListData !== null && <Suspense fallback={<SpinnerComponent />}><RiskList projectId={projectId} riskData={riskListData} /></Suspense>}
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
          <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
            <Typography>Compliance</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="panel 3">
              {auditTestListData !== null && <Suspense fallback={<SpinnerComponent />}><AuditTestList projectId={projectId} auditData={auditTestListData} /></Suspense>}
            </div>
          </AccordionDetails>
        </Accordion>
      </>}

      <Dialog
        //fullScreen
        open={openProjectWizardDialog}
        onClose={handleClose}
        aria-labelledby="create-data"
        aria-describedby="alert-dialog-create-data"
      >
        <DialogActions className="create-edit-dialog">
          <Button startIcon={<CloseIcon />} onClick={handleClose} aria-label="close-btn">Close</Button>
        </DialogActions>
        <DialogTitle id="alert-dialog-title">
          {`Create Project`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <ProjectWizard isOpen={isOpen} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}