import React, { useState, useEffect, useCallback, lazy, Suspense } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import axios from 'axios';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import { Link as UpperLink } from '@mui/material';
import '../css/Project.css';
import { Box } from "@mui/material";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import SpinnerComponent from '../components/LoadingSpinner';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ImportAWSControls from '../components/ImportAWSControls';
import Slide from '@mui/material/Slide';
import FeaturesManagementProject from '../components/FeaturesManagementProject';
const Details = lazy(() => import('./Details'));
const AuditTestList = lazy(() => import('../components/lists/AuditTestList'));
const RiskList = lazy(() => import('../components/lists/RiskList'));
const AssessmentList = lazy(() => import('../components/lists/AssessmentList'));
const CapPoamList = lazy(() => import('../components/lists/CapPoamList'));
const WBSList = lazy(() => import('../components/lists/WBSList'));
const ProjectEvaluationList = lazy(() => import('../components/lists/ProjectEvaluationList'));
const ProjectUserList = lazy(() => import('../components/lists/ProjectUserList'));
const ProjectControlList = lazy(() => import('../components/lists/ProjectControlList'));
const ProjectWizardDocumentList = lazy(() => import('../components/lists/ProjectWizardDocumentList'));
const HistoryList = lazy(() => import('../components/lists/HistoryList'));
const AWSReportCardProject = lazy(() => import('../components/lists/AWSReportProject.js'));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export async function loadData(id) {
  //call data endpoint for data type to set rows and columns
  var url = `${process.env.REACT_APP_API_URL}/projects/${id}`;
  const response = axios(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.access_token}`,
    },
  }).then(result => {
    return result;
  }).catch(error => { return error.message; })
  return response;
}

// get user watching
export async function getUserWatching(id) {
  //call data endpoint for data type to set rows and columns
  var url = `${process.env.REACT_APP_API_URL}/history/user_watching/${id}`;
  const response = axios(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.access_token}`,
    },
  }).then(result => {
    return result;
  }).catch(error => { return error.message; })

  return response;
}

// Load existing feature settings
export async function getFeatureSettings(id) {
  var url = `${process.env.REACT_APP_API_URL}/features/feature_project/${id}`;
  const response = axios(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.access_token}`,
    },
  }).then(result => {
    return result;
  }).catch(error => { return error.message; })
  return response;
}

// get user watching
export async function updateUserWatching(isWatching, method, id, dataType) {
  //call data endpoint for data type to set rows and columns
  var url = `${process.env.REACT_APP_API_URL}/history/user_watching/project/${id}`;
  if (method === "POST") {
    url = `${process.env.REACT_APP_API_URL}/history/user_watching`;
  }
  var body = {
    "project_id": id,
    "user_id": localStorage.getItem("userid"),
    "project_overview": isWatching
  }
  if (dataType === "controls") {
    body = {
      "project_id": id,
      "user_id": localStorage.getItem("userid"),
      "project_controls": isWatching
    }
  }
  if (dataType === "risks") {
    body = {
      "project_id": id,
      "user_id": localStorage.getItem("userid"),
      "project_risks": isWatching
    }
  }
  if (dataType === "audit_tests") {
    body = {
      "project_id": id,
      "user_id": localStorage.getItem("userid"),
      "project_audit_tests": isWatching
    }
  }
  if (dataType === "project_evaluations") {
    body = {
      "project_id": id,
      "user_id": localStorage.getItem("userid"),
      "project_evaluations": isWatching
    }
  }
  if (dataType === "documents") {
    body = {
      "project_id": id,
      "user_id": localStorage.getItem("userid"),
      "project_documents": isWatching
    }
  }
  if (dataType === "users") {
    body = {
      "project_id": id,
      "user_id": localStorage.getItem("userid"),
      "project_users": isWatching
    }
  }
  if (dataType === "wbs") {
    body = {
      "project_id": id,
      "user_id": localStorage.getItem("userid"),
      "project_wbs": isWatching
    }
  }
  if (dataType === "assessments") {
    body = {
      "project_id": id,
      "user_id": localStorage.getItem("userid"),
      "project_assessments": isWatching
    }
  }
  if (dataType === "cap_poams") {
    body = {
      "project_id": id,
      "user_id": localStorage.getItem("userid"),
      "project_cap_poams": isWatching
    }
  }
  const response = axios(url, {
    method: method,
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.access_token}`,
    },
    data: body,
  }).then(result => {
    return result;
  }).catch(error => { return error.message; })

  return response;
}

function Project({ id }) {

  const [isSystemAdmin, setIsSystemAdmin] = useState(false);
  const [importOpen, setImportOpen] = useState(false);
  const [awsSecHubOn, setAwsSecHubOn] = useState(false);
  const [featuresOpen, setFeaturesOpen] = useState(false);

  const isOpen = () => {
    handleClose();
    getData();
  }

  const handleClose = () => {
    setImportOpen(false);
    setFeaturesOpen(false);
    getData();
  }

  const importData = () => {
    setImportOpen(true);
  }

  const featureOpen = () => {
    setFeaturesOpen(true);
  }

  useEffect(() => {
    if (localStorage.getItem("system_role") === "4" ||
      localStorage.getItem("system_role_") === "1" ||
      localStorage.getItem("system_role_") === "2"
    ) {
      setIsSystemAdmin(true)
    }

  }, [setIsSystemAdmin])

  useEffect(() => {
    //console.log(isSystemAdmin)
  }, [isSystemAdmin])

  const [value, setValue] = useState(0);
  const [data, setData] = useState(null);
  const [showHistory, setShowHistory] = useState(false);
  const [checked, setChecked] = useState()
  const [dataType, setDataType] = useState()

  const setWatchToggle = useCallback(async (type) => {
    let response = await getUserWatching(id)
    if (type === "projects" && response?.data?.project_overview === true) {
      return true
    }
    if (type === "controls" && response?.data?.project_controls === true) {
      return true
    }
    if (type === "risks" && response?.data?.project_risks === true) {
      return true
    }
    if (type === "documents" && response?.data?.project_risks === true) {
      return true
    }
    if (type === "project_evaluations" && response?.data?.project_evaluations === true) {
      return true
    }
    if (type === "audit_tests" && response?.data?.project_audit_tests === true) {
      return true
    }
    if (type === "users" && response?.data?.project_users === true) {
      return true
    }
    if (type === "wbs" && response?.data?.project_wbs === true) {
      return true
    }
    if (type === "assessments" && response?.data?.project_assessments === true) {
      return true
    }
    if (type === "cap_poams" && response?.data?.cap_poams === true) {
      return true
    }
    else {
      return false
    }
  }, [id])

  const handleSwitchChange = async () => {
    let response = await getUserWatching(id)
    if (checked === false) {
      setChecked(true)
      if (!response?.data?.id) {
        await updateUserWatching(true, "POST", id, dataType)
      }
      if (response?.data?.id) {
        await updateUserWatching(true, "PUT", id, dataType)
      }
    }
    if (checked === true) {
      setChecked(false)
      if (!response?.data?.id) {
        await updateUserWatching(false, "POST", id, dataType)
      }
      if (response?.data?.id) {
        await updateUserWatching(false, "PUT", id, dataType)
      }

    }
  }

  useEffect(() => {
    var subpath = window.location.pathname.split("/")[3];
    //value 0 projects
    if (!subpath) {
      setValue(0)
      setDataType("projects")
    }
    //value 1 project controls
    if (subpath === "controls") {
      setValue(1)
      setDataType("controls")
    }
    //value 2 risks
    if (subpath === "risks") {
      setValue(2)
      setDataType("risks")
    }
    //value 3 project evaluations
    if (subpath === "project_evaluations") {
      setValue(3)
      setDataType("project_evaluations")
    }
    //value 4 audit tests
    if (subpath === "audit_tests") {
      setValue(4)
      setDataType("audit_tests")
    }
    //value 5 documents
    if (subpath === "documents") {
      setValue(5)
      setDataType("documents")
    }
    //value 6 users
    if (subpath === "users") {
      setValue(6)
      setDataType("users")
    }
    //value 7 wbs
    if (subpath === "wbs") {
      setValue(7)
      setDataType("wbs")
    }
    //value 8 assessments
    if (subpath === "assessments") {
      setValue(8)
      setDataType("assessments")
    }
    //value 9 cap_poams
    if (subpath === "cap_poams") {
      setValue(9)
      setDataType("cap_poams")
    }
  }, [value, checked]);


  const checkWatchToggle = useCallback(async () => {
    setChecked(await setWatchToggle(dataType))
  }, [dataType, setWatchToggle])

  useEffect(() => {
    if (!checked && dataType) {
      checkWatchToggle()
    }
  }, [checked, dataType, checkWatchToggle])

  const onChange = async (e, value) => {
    setValue(value);
    if (value === 0) {
      setDataType("projects")
      setChecked(await setWatchToggle("projects"))
    }
    //value 1 project controls
    if (value === 1) {
      setDataType("controls")
      setChecked(await setWatchToggle("controls"))
    }
    //value 2 risks
    if (value === 2) {
      setDataType("risks")
      setChecked(await setWatchToggle("risks"))
    }
    //value 3 project evaluations
    if (value === 3) {
      setDataType("project_evaluations")
      setChecked(await setWatchToggle("project_evaluations"))
    }
    //value 4 audit tests
    if (value === 4) {
      setDataType("audit_tests")
      setChecked(await setWatchToggle("audit_tests"))
    }
    //value 5 documents
    if (value === 5) {
      setDataType("documents")
      setChecked(await setWatchToggle("documents"))
    }
    //value 6 users
    if (value === 6) {
      setDataType("users")
      setChecked(await setWatchToggle("users"))
    }
    //value 7 wbs
    if (value === 7) {
      setDataType("wbs")
      setChecked(await setWatchToggle("wbs"))
    }
    //value 8 assessments
    if (value === 8) {
      setDataType("assessments")
      setChecked(await setWatchToggle("assessments"))
    }
    //value 9 cap_poams
    if (value === 9) {
      setDataType("cap_poams")
      setChecked(await setWatchToggle("cap_poams"))
    }
  };
  //axios error message
  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
  }, [errMsg])

  const getData = useCallback(async () => {
    const jsonData = await loadData(id);
    if (jsonData.status !== 200) {
      //handle the error
      setErrMsg(`${jsonData.status}`)
    }
    if (jsonData.status === 200) {
      console.log('setData', jsonData.data)
      setData(jsonData.data);
      const jsonDataFeatures = await getFeatureSettings(jsonData.data.id);
      if (jsonDataFeatures.status !== 200) {
        //handle the error
        setErrMsg(`${jsonData.status}`)
      }
      if (jsonDataFeatures.status === 200 && jsonDataFeatures.data) {
        setAwsSecHubOn(jsonDataFeatures.data.is_active)
      }
    }


  }, [id])


  useEffect(() => {
    if (!data) {
      getData();
    }
  }, [data, getData])

  useEffect(() => {
    //console.log('awsSecHubOn', awsSecHubOn)
  }, [awsSecHubOn])


  return (
    <div className="tab-detail project">
      <Breadcrumbs aria-label="breadcrumb">
        <UpperLink underline="hover" color="inherit" href="/system-administration">
          System Administration
        </UpperLink>
        <UpperLink
          underline="hover"
          color="inherit"
          href={`/projects/list`}
        >
          Projects
        </UpperLink>
        <Typography color="text.primary">{data?.name}</Typography>
      </Breadcrumbs>
      <h1>{data?.name}</h1>
      <AppBar position="static">
        <Tabs value={value} onChange={onChange}>
          <Tab label="Project" component={Link} to={`/projects/${id}`} />
          <Tab label="Controls" component={Link} to={`/projects/${id}/controls`} />
          <Tab label="Risks" component={Link} to={`/projects/${id}/risks`} />
          <Tab label="Evaluations" component={Link} to={`/projects/${id}/project_evaluations`} />
          <Tab label="Audit Tests" component={Link} to={`/projects/${id}/audit_tests`} />
          <Tab label="Documents" component={Link} to={`/projects/${id}/documents`} />
          <Tab label="Users" component={Link} to={`/projects/${id}/users`} />
          <Tab label="WBS" component={Link} to={`/projects/${id}/wbs`} />
          <Tab label="Assessments" component={Link} to={`/projects/${id}/assessments`} />
          <Tab label="CAP/POAM" component={Link} to={`/projects/${id}/cap_poams`} />
        </Tabs>
      </AppBar>

      {/* TAB 1 Contents (project) */}
      {value === 0 && (
        <>
          <div className='top-btn-group project-tabs'>
            <FormGroup className="watch-switch">
              <FormControlLabel control={<Switch checked={checked} onChange={() => { handleSwitchChange(); }} />} label="Watch" />
            </FormGroup>
            {(showHistory === false && <Button variant="contained" className="history-btn" onClick={() => { setShowHistory(true); }}>History</Button>)}
            {(showHistory === true && <Button variant="contained" className="history-btn" onClick={() => { setShowHistory(false); }}>List</Button>)}
          </div>
          {showHistory ? <div className="project-history-list"><Suspense fallback={<SpinnerComponent />}><HistoryList dataType="projects" id={data?.id} /></Suspense></div> : <Box sx={{ p: 3 }}>
            {isSystemAdmin && <Button onClick={featureOpen}>Manage Features</Button>}
            <div className='project details'>
              <Suspense fallback={<SpinnerComponent />}>
                <div className="project-details">
                  <Details dataType="projects" id={id} />
                </div>
              </Suspense>
              {awsSecHubOn && <>
                <Suspense fallback={<SpinnerComponent />}>
                  <div className="awsreportcard">
                    <AWSReportCardProject projectId={id} />
                  </div></Suspense></>}
            </div>
          </Box>}

        </>
      )}
      {/* TAB 2 Contents (project controls)*/}
      {value === 1 && (
        <>
          <div className='top-btn-group project-tabs'>
            <FormGroup className="watch-switch">
              <FormControlLabel control={<Switch checked={checked} onChange={() => { handleSwitchChange(); }} />} label="Watch" />
            </FormGroup>
            {(showHistory === false && <Button variant="contained" className="history-btn" onClick={() => { setShowHistory(true); }}>History</Button>)}
            {(showHistory === true && <Button variant="contained" className="history-btn" onClick={() => { setShowHistory(false); }}>List</Button>)}
            {awsSecHubOn && <Button onClick={importData}>Import AWS Security Hub Controls</Button>}
          </div>
          {showHistory ? <div className="project-history-list"><Suspense fallback={<SpinnerComponent />}><HistoryList dataType="project_controls" id={data?.id} /></Suspense></div> : <Box sx={{ p: 3 }}>
            <Suspense fallback={<SpinnerComponent />}><ProjectControlList projectId={id} /></Suspense>
          </Box>}

        </>
      )}
      {/* TAB 3 Contents (Risks) */}
      {value === 2 && (
        <>
          <div className='top-btn-group project-tabs'>
            <FormGroup className="watch-switch">
              <FormControlLabel control={<Switch checked={checked} onChange={() => { handleSwitchChange(); }} />} label="Watch" />
            </FormGroup>
            {(showHistory === false && <Button variant="contained" className="history-btn" onClick={() => { setShowHistory(true); }}>History</Button>)}
            {(showHistory === true && <Button variant="contained" className="history-btn" onClick={() => { setShowHistory(false); }}>List</Button>)}
          </div>
          {showHistory ? <div className="project-history-list"><Suspense fallback={<SpinnerComponent />}><HistoryList dataType="risks" id={data?.id} /></Suspense></div> : <Box sx={{ p: 3 }}>
            <Suspense fallback={<SpinnerComponent />}><RiskList projectId={id} /></Suspense>
          </Box>}
        </>
      )}
      {/* TAB 4 Contents (evaluations)*/}
      {value === 3 && (
        <>
          <div className='top-btn-group project-tabs'>
            <FormGroup className="watch-switch">
              <FormControlLabel control={<Switch checked={checked} onChange={() => { handleSwitchChange(); }} />} label="Watch" />
            </FormGroup>
            {(showHistory === false && <Button variant="contained" className="history-btn" onClick={() => { setShowHistory(true); }}>History</Button>)}
            {(showHistory === true && <Button variant="contained" className="history-btn" onClick={() => { setShowHistory(false); }}>List</Button>)}
          </div>
          {showHistory ? <div className="project-history-list"><Suspense fallback={<SpinnerComponent />}><HistoryList dataType="project_evaluations" id={data?.id} /></Suspense></div> : <Box sx={{ p: 3 }}>
            <Suspense fallback={<SpinnerComponent />}><ProjectEvaluationList projectId={id} /></Suspense>
          </Box>}
        </>
      )}
      {/* TAB 5 Contents (Audit Tests) */}
      {value === 4 && (
        <>
          <div className='top-btn-group project-tabs'>
            <FormGroup className="watch-switch">
              <FormControlLabel control={<Switch checked={checked} onChange={() => { handleSwitchChange(); }} />} label="Watch" />
            </FormGroup>
            {(showHistory === false && <Button variant="contained" className="history-btn" onClick={() => { setShowHistory(true); }}>History</Button>)}
            {(showHistory === true && <Button variant="contained" className="history-btn" onClick={() => { setShowHistory(false); }}>List</Button>)}
          </div>
          {showHistory ? <div className="project-history-list"><Suspense fallback={<SpinnerComponent />}><HistoryList dataType="audit_tests" id={data?.id} /></Suspense></div> : <Box sx={{ p: 3 }}>
            <div className="audit-test-list"><Suspense fallback={<SpinnerComponent />}><AuditTestList projectId={id} /></Suspense></div>
          </Box>}
        </>
      )}
      {/* TAB 6 Contents (Documents) */}
      {value === 5 && (
        <>
          <div className='top-btn-group project-tabs'>
            <FormGroup className="watch-switch">
              <FormControlLabel control={<Switch checked={checked} onChange={() => { handleSwitchChange(); }} />} label="Watch" />
            </FormGroup>
            {(showHistory === false && <Button variant="contained" className="history-btn" onClick={() => { setShowHistory(true); }}>History</Button>)}
            {(showHistory === true && <Button variant="contained" className="history-btn" onClick={() => { setShowHistory(false); }}>List</Button>)}
          </div>
          {showHistory ? <div className="project-history-list"><Suspense fallback={<SpinnerComponent />}><HistoryList dataType="documents" id={data?.id} /></Suspense></div> : <Box sx={{ p: 3 }}>
            <Suspense fallback={<SpinnerComponent />}><ProjectWizardDocumentList projectId={id} dataId={id} /></Suspense>
          </Box>}
        </>
      )}
      {/* TAB 7 Contents (Users) */}
      {value === 6 &&
        <>

          {isSystemAdmin === false &&
            <Stack sx={{ width: '100%' }} spacing={2} className="alerts">
              <Alert severity="warning">You don't have permission to view this</Alert>
            </Stack>}
          {isSystemAdmin === true &&
            <>
              <div className='top-btn-group project-tabs'>
                <FormGroup className="watch-switch">
                  <FormControlLabel control={<Switch checked={checked} onChange={() => { handleSwitchChange(); }} />} label="Watch" />
                </FormGroup>

                {(showHistory === false && <Button variant="contained" className="history-btn" onClick={() => { setShowHistory(true); }}>History</Button>)}
                {(showHistory === true && <Button variant="contained" className="history-btn" onClick={() => { setShowHistory(false); }}>List</Button>)}
              </div>
              {showHistory ? <div className="project-history-list"><Suspense fallback={<SpinnerComponent />}><HistoryList dataType="project_users" id={data?.id} /></Suspense></div> : <Box sx={{ p: 3 }}>
                <Suspense fallback={<SpinnerComponent />}><ProjectUserList projectId={id} /></Suspense>
              </Box>}</>}
        </>
      }
      {/* TAB 8 Contents (WBS) */}
      {value === 7 && (
        <>
          <div className='top-btn-group project-tabs'>
            <FormGroup className="watch-switch">
              <FormControlLabel control={<Switch checked={checked} onChange={() => { handleSwitchChange(); }} />} label="Watch" />
            </FormGroup>
            {(showHistory === false && <Button variant="contained" className="history-btn" onClick={() => { setShowHistory(true); }}>History</Button>)}
            {(showHistory === true && <Button variant="contained" className="history-btn" onClick={() => { setShowHistory(false); }}>List</Button>)}
          </div>
          {showHistory ? <div className="project-history-list"><Suspense fallback={<SpinnerComponent />}><HistoryList dataType="wbs" id={data?.id} /></Suspense></div> : <Box sx={{ p: 3 }}>
            <Suspense fallback={<SpinnerComponent />}><WBSList projectId={id} /></Suspense>
          </Box>}
        </>
      )}
      {/* TAB 9 Contents (Assessments) */}
      {value === 8 && (
        <>
          <div className='top-btn-group project-tabs'>
            <FormGroup className="watch-switch">
              <FormControlLabel control={<Switch checked={checked} onChange={() => { handleSwitchChange(); }} />} label="Watch" />
            </FormGroup>
            {(showHistory === false && <Button variant="contained" className="history-btn" onClick={() => { setShowHistory(true); }}>History</Button>)}
            {(showHistory === true && <Button variant="contained" className="history-btn" onClick={() => { setShowHistory(false); }}>List</Button>)}
          </div>
          {showHistory ? <div className="project-history-list"><Suspense fallback={<SpinnerComponent />}><HistoryList dataType="assessments" id={data?.id} /></Suspense></div> : <Box sx={{ p: 3 }}>
            <Suspense fallback={<SpinnerComponent />}><AssessmentList projectId={id} /></Suspense>
          </Box>}
        </>
      )}
      {/* TAB 10 Contents (CAP/POAMS) */}
      {value === 9 && (
        <>
          <div className='top-btn-group project-tabs'>
            <FormGroup className="watch-switch">
              <FormControlLabel control={<Switch checked={checked} onChange={() => { handleSwitchChange(); }} />} label="Watch" />
            </FormGroup>
            {(showHistory === false && <Button variant="contained" className="history-btn" onClick={() => { setShowHistory(true); }}>History</Button>)}
            {(showHistory === true && <Button variant="contained" className="history-btn" onClick={() => { setShowHistory(false); }}>List</Button>)}
          </div>
          {showHistory ? <div className="project-history-list"><Suspense fallback={<SpinnerComponent />}><HistoryList dataType="cap_poams" id={data?.id} /></Suspense></div> : <Box sx={{ p: 3 }}>
            <Suspense fallback={<SpinnerComponent />}><CapPoamList projectId={id} /></Suspense>
          </Box>}
        </>
      )}
      <Dialog
        //fullScreen
        open={importOpen}
        TransitionComponent={Transition}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        className={`dialog edit`}
      >
        <DialogActions className="create-edit-dialog">
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
        <DialogTitle>Import AWS Controls</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <ImportAWSControls projectId={id} isOpen={isOpen} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Dialog
        open={featuresOpen}
        TransitionComponent={Transition}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        className={`dialog edit`}
      >
        <DialogActions className="feature-dialog">
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
        <DialogTitle>Manage features</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <FeaturesManagementProject projectId={data?.id} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default Project;